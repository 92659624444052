import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MovimentacaoApiService } from 'src/app/comum/services/movimentacao-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SeletorMesAnoComponent } from 'src/app/comum/components/seletor-mes-ano/seletor-mes-ano.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmacaoDialogComponent } from 'src/app/comum/components/confirmacao-dialog/confirmacao-dialog.component';
import * as moment from 'moment';
import { ExportToCsv } from 'export-to-csv';
import { Observable } from 'rxjs';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'soi-entrada-movimentacoes',
  templateUrl: './entrada-movimentacoes.component.html',
  styleUrls: ['./entrada-movimentacoes.component.css']
})
export class EntradaMovimentacoesComponent {

  // controla se ambiente é KIN 
  env: any;

  //#region Dados necessários para busca de movimentação
  /** Valor atual do seletor de indústria. */
  industriaSelecionada: any;

  /** Valor atual do seletor de distribuidora. */
  distribuidoraSelecionada: any;

  /** Valor atual do seletor de período. */
  periodoSelecionado: any;

  //#endregion

  //#region Dados necessários ao funcionamento da tabela de movimentação.

  /** Colunas da tabela de movimentação. */
  colunasTabelaMovimentacao: string[];

  /** Fonte de dados da tabela de movimentação. */
  dataSourceTabelaMovimentacao: MatTableDataSource<any>;

  /** Paginador da tabela de movimentação. */
  @ViewChild(MatPaginator)
  paginadorTabelaMovimentacao: MatPaginator;

  /** Sort da tabela de movimentação. */
  @ViewChild(MatSort)
  sortTabelaMovimentacao: MatSort;

  /** Colunas Totais (quantidade, estoque e valor) */
  totalQuantidade = 0;
  totalEstoque = 0;
  totalValor = 0;

  //#endregion

  /**
   * Componente de seleção de período (mês e ano).
   * Utilizado como ViewChild aqui para alterar o valor do período selecionado com base
   * no valor passado pelo componente pai e evitar ExpressionChangedAfterItHasBeenCheckedError.
   */
  @ViewChild(SeletorMesAnoComponent)
  private seletorMesAno: SeletorMesAnoComponent;

  /** Movimentação que está sendo mostrada na tela atualmente. */
  movimentacaoAtual: any;

  /** Produtos associados a Indústria e Distribuidora buscados atualmente */
  produtosAssociados: any;

  /** Linguagem atualmente selecionada */
  lingua = localStorage.getItem('lingua');

  /** Indica se a tabela de movimentação foi renderizada. */
  tabelaMovimentacaoRenderizada: boolean = false;

  /**
   * Indica se a movimentação que o backend retornou é de um mês anterior ao mês selecionado para busca.
   * Necessário pois foi definido que não será possível iniciar uma Movimentação enquanto existir outra aberta.
   */
  movimentacaoAbertaMesAnterior: boolean = false;

  /** Indica se a movimentação atual não está finalizada e, portanto, pode ser editada. */
  edicaoMovimentacaoHabilitada: boolean = false;

  /** Mensagem do status da movimentação: "Em progesso", "Finalizado". */
  statusMovimentacao: string;

  /** Indica se o backend não encontrou produtos associados à distribuidora. */
  distribuidoraSemProdutos: boolean = false;

  mesNaoRegistrado: boolean = false;

  constructor(
    private movimentacaoApiService: MovimentacaoApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.env = environment.client;
  }

  setColunasTabelaMovimentacao() {
    if (this.env == 'Kin') {
      if (this.industriaSelecionada && this.industriaSelecionada.id == 2) { //KIN CA
        this.colunasTabelaMovimentacao = ['CodigoProdutoIndustria', 'Descricao', 'Quantidade', 'Estoque', 'Valor'];
      } else { //KIN ES
        this.colunasTabelaMovimentacao = ['CodigoProdutoIndustria', 'Descricao', 'Quantidade', 'Estoque'];
      }
    } else {
      this.colunasTabelaMovimentacao = ['CodigoProdutoIndustria', 'Descricao', 'Quantidade'];
    }
  }

  setIndustriaSelecionada(industria) {
    this.industriaSelecionada = industria;
  }

  setDistribuidoraSelecionada(distribuidora) {
    this.distribuidoraSelecionada = distribuidora;
  }

  setPeriodoSelecionado(periodoSelecionado: Date) {
    this.periodoSelecionado = periodoSelecionado;
  }

  buscarDadosTabelaMovimentacao() {
    this.dataSourceTabelaMovimentacao = null;
    this.tabelaMovimentacaoRenderizada = false;
    this.movimentacaoAbertaMesAnterior = false;

    let parametrosBuscaMovimentacao = {
      idDistribuidora: this.distribuidoraSelecionada?.id,
      idIndustria: this.industriaSelecionada?.id,
      periodo: this.periodoSelecionado
    };

    if (!parametrosBuscaMovimentacao.idDistribuidora || !parametrosBuscaMovimentacao.idIndustria) {
      this.snackBar.open(this.translate.instant('Selecione corretamente os parâmetros'), '', { duration: 2000 });
      return;
    }

    this.setColunasTabelaMovimentacao();

    return this.movimentacaoApiService.getDadosMovimentacao(parametrosBuscaMovimentacao)
      .subscribe(
        resposta => {
          if (resposta.status == 204) {
            this.distribuidoraSemProdutos = true;

            return;
          }
          this.exibirMensagem(resposta.body);

          this.movimentacaoAtual = resposta.body.dados.movimentacao;
          this.produtosAssociados = resposta.body.dados.produtos;

          this.distribuidoraSemProdutos = false;
          this.mesNaoRegistrado = false;

          if (this.movimentacaoAtual != null) {
            if ((this.movimentacaoAtual.periodo < this.periodoSelecionado) && !this.movimentacaoAtual.finalizado) {
              this.movimentacaoAbertaMesAnterior = true;
              this.setPeriodoSelecionado(this.movimentacaoAtual.periodo);
            }

            this.seletorMesAno.mesAno = this.movimentacaoAtual.periodo;
            this.statusMovimentacao = this.movimentacaoAtual.finalizado ? "Finalizado" : "Em progresso";
          } else {
            this.statusMovimentacao = "Período não registrado";
            this.mesNaoRegistrado = true;
            this.movimentacaoAtual = {
              detalhes: [],
              finalizado: false,
              idIndustria: this.industriaSelecionada?.id,
              idDistribuidora: this.distribuidoraSelecionada?.id,
              periodo: this.periodoSelecionado,
              idMovimentacao: 0
            };
          }

          if (this.produtosAssociados != null && this.produtosAssociados.length != 0) {
            if (!this.movimentacaoAtual.finalizado && this.movimentacaoAtual.idMovimentacao > 0) {
              this.misturarProdutosAssociados();
            }
          } else {
            this.distribuidoraSemProdutos = true;
          }

          if (this.movimentacaoAtual?.detalhes.length > 0) {
            this.movimentacaoAtual?.detalhes.sort((a, b) => a.descricao > b.descricao ? 1 : -1);
            this.construirTabelaMovimentacao();
          }

          this.seletorMesAno.mesAno = this.movimentacaoAtual.periodo;

          this.getTotais(); //calcula os totais das colunas 
        },
        error => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'), '', { duration: 2000 });
        });
  }

  misturarProdutosAssociados() {
    this.distribuidoraSemProdutos = false;
    for (let i in this.produtosAssociados) {
      let jaExiste = false;
      for (let j in this.movimentacaoAtual.detalhes) {
        if (this.produtosAssociados[i].idProduto == this.movimentacaoAtual.detalhes[j].idProduto) {
          jaExiste = true;
        }
      }

      if (!jaExiste) {
        this.movimentacaoAtual.detalhes.push({
          codigoProdutoAgrupado: this.produtosAssociados[i].codigoProdutoAgrupado,
          codigoProdutoIndustria: this.produtosAssociados[i].codigoProdutoIndustria,
          descricao: this.produtosAssociados[i].descricao,
          descricaoIngles: this.produtosAssociados[i].descricaoIngles,
          descricaoEspanhol: this.produtosAssociados[i].descricaoEspanhol,
          descricaoFrances: this.produtosAssociados[i].descricaoFrances,
          idMovimentacao: this.movimentacaoAtual.idMovimentacao,
          idMovimentacaoDetalhe: 0, // 0 TODO
          idProduto: this.produtosAssociados[i].idProduto,
          quantidade: 0,
          estoque: 0,
          valor: 0
        });
      }
    }
  }

  construirTabelaMovimentacao() {
    this.dataSourceTabelaMovimentacao = new MatTableDataSource(this.movimentacaoAtual.detalhes);
    this.dataSourceTabelaMovimentacao.paginator = this.paginadorTabelaMovimentacao;
    this.dataSourceTabelaMovimentacao.paginator.pageIndex = 0;
    this.dataSourceTabelaMovimentacao.sort = this.sortTabelaMovimentacao;
    this.tabelaMovimentacaoRenderizada = this.dataSourceTabelaMovimentacao.data.length > 0;
    this.edicaoMovimentacaoHabilitada = this.statusMovimentacao == "Em progresso";
  }

  construirFiltro() {
    this.dataSourceTabelaMovimentacao.filterPredicate = (data, filter) => {
      let dataStr = data.codigoProdutoIndustria.toString().concat(data.quantidade.toString());

      switch (this.lingua) {
        case 'en':
          dataStr += data.descricaoIngles;
          break;
        case 'es':
          dataStr += data.descricaoEspanhol;
          break;
        case 'fr':
          dataStr += data.descricaoFrances;
          break;
        default:
          dataStr += data.descricao;
          break;
      }

      return dataStr.toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1;
    }
  }

  filtrarTabela(filtro: string) {
    this.dataSourceTabelaMovimentacao.filter = filtro.toLowerCase();
  }

  salvarMovimentacao() {
    this.movimentacaoApiService.salvarMovimentacao(this.movimentacaoAtual)
      .subscribe(
        retornoPadronizado => {
          this.exibirMensagem(retornoPadronizado);

          if (retornoPadronizado.dados) {
            this.movimentacaoAtual = retornoPadronizado.dados;
          }

          this.construirTabelaMovimentacao();
        },
        error => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'), '', { duration: 2000 });
        });
  }

  exportarMovimentacao() {

    let filtro = {
      periodo: this.periodoSelecionado,
      idIndustria: this.industriaSelecionada?.id ?? 0,
      idDistribuidora: this.distribuidoraSelecionada?.id ?? 0
    };

    this.movimentacaoApiService.getExportacao(filtro)
      .subscribe(resposta => {
        this.exibirMensagem(resposta);

        if (resposta.sucesso == false) {
          return;
        }

        let dadosExportacao = resposta.dados;

        if (this.env == 'Kin') {
          if (filtro.idIndustria != 2) {
            dadosExportacao.forEach(de => {
              delete de.valor;
            });
          }
        } else { //se nao for KIN, remove colunas do relatorio
          dadosExportacao.forEach(de => {
            delete de.valor;
            delete de.estoque;
          });
        }

        let nomeDistribuidora = !this.distribuidoraSelecionada || this.distribuidoraSelecionada == 0 ?
          this.translate.instant('Todas') :
          this.distribuidoraSelecionada.nome;

        let filename = this.translate.instant('Movimentações') +
          '_' + nomeDistribuidora + '_' +
          moment(filtro.periodo).format('YYYYMM');

        const options = {
          filename: filename,
          fieldSeparator: ';',
          quoteStrings: '',
          decimalSeparator: '.',
          showLabels: false,
          showTitle: false,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(dadosExportacao);
      });
  }

  enviarMovimentacao() {
    this.confirmarFinalizacaoMovimentacao()
      .subscribe(
        confirmado => {
          if (confirmado) {
            this.movimentacaoAtual.finalizado = true;
            this.movimentacaoApiService.salvarMovimentacao(this.movimentacaoAtual)
              .subscribe(
                retornoPadronizado => {
                  this.exibirMensagem(retornoPadronizado);
                  if (retornoPadronizado.sucesso == false) {
                    return;
                  }
                  this.statusMovimentacao = "Finalizado";
                  this.movimentacaoAtual = retornoPadronizado.dados;
                  this.construirTabelaMovimentacao();
                  this.edicaoMovimentacaoHabilitada = false;
                  this.exibirMensagem(retornoPadronizado);

                },
                error => {
                  this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'), '', { duration: 2000 });
                }
              );
          }
        });
  }

  confirmarFinalizacaoMovimentacao(): Observable<boolean> {
    let mensagemDialog: string = this.translate.instant('Deseja mesmo enviar esses dados? Não será possível editá-los posteriormente.');
    let dialogOptions: MatDialogConfig = {
      data: {
        mensagem: mensagemDialog
      }
    }
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, dialogOptions);

    return dialogRef.afterClosed();
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }

  getTotais() {
    this.totalQuantidade = this.movimentacaoAtual.detalhes.map(m => m.quantidade).reduce((acc, value) => acc + value, 0);
    this.totalEstoque = this.movimentacaoAtual.detalhes.map(m => m.estoque).reduce((acc, value) => acc + value, 0);
    this.totalValor = this.movimentacaoAtual.detalhes.map(m => m.valor).reduce((acc, value) => acc + value, 0);
    this.totalValor = Number(this.totalValor.toFixed(2));
  }


}
