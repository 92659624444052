import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
@Injectable({
  providedIn: 'root'
})
export class IndustriaApiService extends BaseApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  getIndustrias() {
    return this.get('Industria');
  }
}
