import { Component, OnInit } from '@angular/core';
import { DistribuidoraDashboardApiService } from 'src/app/industria/services/distribuidora-dashboard-api.service';
import { CarregamentoService } from 'src/app/comum/services/carregamento.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { transformAll } from '@angular/compiler/src/render3/r3_ast';


@Component({
  selector: 'app-dashboard-distribuidor',
  templateUrl: './dashboard-distribuidor.component.html',
  styleUrls: ['./dashboard-distribuidor.component.css']
})
export class DashboardDistribuidorComponent implements OnInit {

    distribuidoraSelecionada: any = null;
    selectedYear:any = null;

    linkPowerBiDist:any = '';

    mostrarGrafico:boolean = false;
  
  constructor(
    private distribuidoraDashboardApiService: DistribuidoraDashboardApiService,
    private carregamentoService: CarregamentoService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    //this.carregamentoService.mostrar();


   
  }

  setPeriodoSelecionado(event:any){

  }

  setIndustriaSelecionada(event:any){

  }

  setDistribuidoraSelecionada(distribuidora){
    this.distribuidoraSelecionada = distribuidora;
  }

  buscarUrlAcessoDistribuidora(){

    this.mostrarGrafico = false;
      this.carregamentoService.mostrar();

      if(this.distribuidoraSelecionada != null){ 

        this.distribuidoraDashboardApiService.getDistribuidorasDashboard(this.distribuidoraSelecionada.id)
        .subscribe(
          resposta => {
        
            if (resposta != null && resposta.dados != null){
              this.mostrarGrafico = true;
              this.linkPowerBiDist = this.transform(resposta.dados.acessoBi);
            }else{
              this.snackBar.open(this.translate.instant('Dashboard não disponível para esta distribuidora'), '', { duration: 2000 });
            }
            this.carregamentoService.esconder();
      
          },
          erro => {
            console.log(erro);
          });
      }else{
        this.snackBar.open(this.translate.instant('Selecione corretamente os parâmetros'), '', { duration: 2000 });
        this.carregamentoService.esconder();
      }

     

    }
    
    transform(url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }


}
