import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/comum/services/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class EmailAvisoApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  inserirEmailAviso(valor: any) {
    return this.post(valor, 'EmailEnvio');
  }

  getEmailsPorIndustria(idIndustria, idDistribuidora) {
    return this.get(`EmailEnvio/GetByIndustria/${idIndustria}?idDistribuidora=${idDistribuidora}`);
  }

  deletarEmail(email) {
    return this.delete(`EmailEnvio/${email.idEmailEnvio}`);
  }

  atualizarEmail(email) {
    return this.update(email, `EmailEnvio/${email.idEmailEnvio}`);
  }
}
