import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/comum/services/base-api.service';
import { throwError, Observable, Subject } from 'rxjs';
import { SessionService } from './session.service';
import { PerfilUsuario } from 'src/app/compartilhado/enums';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseApiService {
  constructor(
    httpClient: HttpClient,
    private session: SessionService,
    private router: Router
  ) {
    super(httpClient);
  }

  loginSimples(usuario: string, senha: string): Observable<Object> {
    const dados = {
      usuario: usuario,
      senha: senha
    };

    return this.post(dados, '/Login/');
  }

  async loginComTokenPainel(token): Promise<Object> {

    return this.post(token, '/Login/AutenticarTokenEcomm/');
  }

  tratarErro(erro) {
    return throwError(erro);
  }

  redirecionarAposLogin() {
    switch (this.session.perfilUsuario.id) {
      case PerfilUsuario.Distribuidora:
        this.router.navigate(['/distributor/sales-report']); break;
      default:
        this.router.navigate(['/industry/transactions']); break;
    }
  }
}
