<div class="seletor-flex">
<mat-form-field appearance="outline">
    <mat-label>{{ "Indústria" | translate }}</mat-label>
    <mat-select [(ngModel)]="industria" (selectionChange)="emitirIndustria()">
        <mat-option *ngFor="let industria of industrias" [value]="industria">
            {{ industria.nome }}
        </mat-option>
    </mat-select>
</mat-form-field>


<mat-form-field appearance="outline">
    <mat-label>{{ "País" | translate }}</mat-label>
    <mat-select [(ngModel)]="pais" (selectionChange)="filtrarDistribuidorasPorPais($event.value)">
        <mat-option *ngFor="let pais of paisesDaIndustria" [value]="pais">{{ pais.nome | translate }}</mat-option>
    </mat-select>
</mat-form-field>


<mat-form-field appearance="outline">
    <mat-label>{{ "Distribuidora" | translate }}</mat-label>
    <mat-select [(ngModel)]="distribuidora" (selectionChange)="emitirDistribuidora()">
        <mat-option *ngFor="let distribuidora of distribuidorasPorPais" [value]="distribuidora">
            {{ distribuidora.nomeCompleto }}
            <ng-template [ngIf]="distribuidora.idSistemaExterno > 0">
                - {{ distribuidora.idSistemaExterno }}
            </ng-template>
        </mat-option>
    </mat-select>
</mat-form-field>
</div>
