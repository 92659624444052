<div mat-dialog-content>
    <form [formGroup]="emailAvisoForm" (ngSubmit)="enviar()">
        <soi-seletor-industria-distribuidora (distribuidoraSelecionada)="setIdIndustriaDistribuidora($event)">
        </soi-seletor-industria-distribuidora>

        <mat-form-field appearance="outline">
            <mat-label>{{"Endereço de e-mail" | translate}}</mat-label>
            <input matInput formControlName="email" required />
        </mat-form-field>

        <div *ngIf="email.invalid && (email.dirty || email.touched)" class="form-field-error">
            {{ "E-mail inválido" | translate }}
        </div>

        <mat-form-field appearance="outline">
            <mat-label>{{"Dia de envio" | translate}}</mat-label>
            <input matInput formControlName="diaMesEnvio" type="number" min="1" max="31" required />
        </mat-form-field>

        <div *ngIf="diaMesEnvio.invalid && (diaMesEnvio.dirty || diaMesEnvio.touched)" class="form-field-error">
            {{ "Dia inválido" | translate }}
        </div>
    </form>
</div>

<div mat-dialog-actions>
    <button mat-raised-button class="acao-botao" (click)="cancelar()">
        <mat-icon>close</mat-icon>
    </button>
    <button mat-raised-button color="primary" [disabled]="!emailAvisoForm.valid" class="acao-botao" (click)="enviar()">
        <mat-icon>done</mat-icon>
    </button>
</div>