import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './comum/components/login/login.component';
import { LogoutComponent } from './comum/components/logout/logout.component';
import { DadosMovimentacoesComponent } from './industria/components/consultas/dados-movimentacoes/dados-movimentacoes.component';
import { StatusMovimentacoesComponent } from './industria/components/consultas/status-movimentacoes/status-movimentacoes.component';
import { PaginaNaoEncontradaComponent } from './comum/components/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { PaginaComErroComponent } from './comum/components/pagina-com-erro/pagina-com-erro.component';
import { EntradaMovimentacoesComponent } from './distribuidora/components/entrada-movimentacoes/entrada-movimentacoes.component';
import { AssociacaoMixProdutosComponent } from './industria/components/configuracoes/associacao-mix-produtos/associacao-mix-produtos.component';
import { AuthGuard } from './compartilhado/auth/auth.guard';
import { Rotas } from 'src/app/compartilhado/enums';
import { EnvioEmailsAvisoListaComponent } from './industria/components/configuracoes/envio-emails-aviso-lista/envio-emails-aviso-lista.component';
import { DashboardDistribuidorComponent } from './comum/components/dashboard-distribuidor/dashboard-distribuidor.component';
import { ProdutosComponent } from './industria/components/cadastros/produtos/produtos.component';
import { DistribuidorasComponent } from './industria/components/cadastros/distribuidoras/distribuidoras.component';
import { UsuariosIndustriaComponent } from './industria/components/cadastros/usuario-industria/usuario-industria.component';


const routes: Routes = [
  { path: '', redirectTo: Rotas.Login, pathMatch: 'full' },
  { path: Rotas.Login, component: LoginComponent },
  { path: Rotas.Logout, component: LogoutComponent },
  { path: Rotas.CadastroProdutos, component: ProdutosComponent, canActivate: [AuthGuard] },
  { path: Rotas.AssociacaoMixProdutos, component: AssociacaoMixProdutosComponent, canActivate: [AuthGuard] },
  { path: Rotas.EmailsAviso, component: EnvioEmailsAvisoListaComponent, canActivate: [AuthGuard] },
  { path: Rotas.Movimentacoes, component: DadosMovimentacoesComponent, canActivate: [AuthGuard] },
  { path: Rotas.StatusMovimentacoes, component: StatusMovimentacoesComponent, canActivate: [AuthGuard] },
  { path: Rotas.PainelDistribuidor, component: EntradaMovimentacoesComponent, canActivate: [AuthGuard] },
  { path: Rotas.DashboardDistribuidor, component: DashboardDistribuidorComponent, canActivate: [AuthGuard] },
  { path: Rotas.TokenEcommerce, component: LoginComponent, canActivate: [AuthGuard] },
  { path: Rotas.Distribuidoras, component: DistribuidorasComponent }, //, canActivate: [AuthGuard] },
  { path: Rotas.CadastroUsuarios, component: UsuariosIndustriaComponent }, // canActivate: [AuthGuard] },
  { path: Rotas.PageError, component: PaginaComErroComponent },
  { path: Rotas.NotFound, component: PaginaNaoEncontradaComponent },
  { path: '**', redirectTo: Rotas.PageError }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
