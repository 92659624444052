import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'soi-patch-input',
  templateUrl: './patch-input.component.html',
  styleUrls: ['./patch-input.component.css']
})
export class PatchInputComponent {
  @Input()
  value: string;

  @Input()
  type: string;

  @Input()
  textAlign: string;

  @Input()
  width: string;

  @Input()
  validators: string[] = [];

  @Input()
  mensagemErro: string;

  @Output()
  salvarValor: EventEmitter<any> = new EventEmitter<any>();

  valueAlterado: boolean;

  valueAntigo: any;

  patchInputForm: FormGroup;

  get inputValue() {
    return this.patchInputForm.get('patchInputValue');
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.patchInputForm = this.fb.group({
      patchInputValue: this.fb.control([this.value])
    });
    this.setInputValidators();
    this.valueAlterado = false;
    this.valueAntigo = this.inputValue.value.slice();
  }

  habilitarAcoes() {
    if (this.inputValue.value == this.valueAntigo) {
      this.valueAlterado = false;
    }
    else {
      this.valueAlterado = true;
    }
  }

  desfazer() {
    this.inputValue.setValue(this.valueAntigo);
    this.valueAlterado = false;
  }

  salvar() {
    this.salvarValor.emit(this.inputValue.value);
    this.valueAlterado = false;
  }

  setInputValidators() {
    if (this.validators.includes('email')) {
      this.inputValue.setValidators([Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
    }

    if (this.validators.includes('diaMes')) {
      this.inputValue.setValidators([Validators.min(1), Validators.max(31), Validators.pattern('^[0-9]*$')]);
    }
  }
}
