import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ParametrosImportacaoArquivo {
  idIndustria: number,
  idDistribuidora: number,
  arquivo: File
}

@Injectable({
  providedIn: 'root'
})
export class ImportarArquivoService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  importarArquivo(body: ParametrosImportacaoArquivo): Observable<any> {
    let formData = new FormData();
    formData.append('file', body.arquivo, body.arquivo.name);
    formData.append('idIndustria', body.idIndustria?.toString());
    formData.append('idDistribuidora', body.idDistribuidora?.toString());

    return this.httpClient.post(this.construirUrl('Movimentacao/ImportarArquivo'), formData);
  }
}
