import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/comum/services/base-api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MovimentacaoApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getMovimentacao(filtro: any) {
    return this.post(filtro, 'Movimentacao/GetMovimentacao/');
  }

  getStatusMovimentacao(filtro: any) {
    return this.post(filtro, 'Movimentacao/GetStatusMovimentacao/');
  }

  getExportacao(filtro: any) {
    return this.post(filtro, 'Movimentacao/Exportacao/');
  }

  alterarStatusMovimentacao(idMovimentacao, status) {
    return this.patch(`Movimentacao/${idMovimentacao}/AlterarStatus`, status);
  }

  getMovimentacaoDashboard(industria: number) {
    return this.get(`Movimentacao/Industria/${industria}`);
  }
}
