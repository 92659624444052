import { Component } from '@angular/core';
import { MovimentacaoApiService } from 'src/app/industria/services/movimentacao-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';

@Component({
  selector: 'soi-status-movimentacoes',
  templateUrl: './status-movimentacoes.component.html',
  styleUrls: ['./status-movimentacoes.component.css']
})
export class StatusMovimentacoesComponent {
  industriaSelecionada: any;
  distribuidoraSelecionada: any;
  periodoSelecionado: Date;
  todasDistribuidoras: number = 0;

  listaStatus: Array<any>;
  listaFiltrada: Array<any>;

  statusOptions = [{
    valor: 0,
    descricao: 'Todos'
  },
  {
    valor: 1,
    descricao: 'Finalizado'
  }, {
    valor: 2,
    descricao: 'Em progresso'
  }];
  filtroStatus: any = this.statusOptions[0];

  constructor(
    private movimentacaoApiService: MovimentacaoApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService) { }

  setIndustriaSelecionada(industria) {
    this.industriaSelecionada = industria;
  }

  setDistribuidoraSelecionada(distribuidora) {
    this.distribuidoraSelecionada = distribuidora;
  }

  setPeriodoSelecionado(periodoSelecionado: Date) {
    this.periodoSelecionado = periodoSelecionado;
  }

  getMovimentacao() {
    let filtro = {
      periodo: this.periodoSelecionado,
      idIndustria: this.industriaSelecionada.id,
      idDistribuidora: this.distribuidoraSelecionada?.id | this.todasDistribuidoras
    };

    this.movimentacaoApiService.getStatusMovimentacao(filtro)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }
          let resultado = resposta.dados;

          this.filtroStatus = this.statusOptions[0];
          this.listaStatus = Object.values(this.groupBy(resultado, 'periodo'));
          this.ordenarListaStatusPorData();
          this.listaFiltrada = this.listaStatus.map(x => ({ ...x }));

          if (typeof this.listaStatus === 'undefined' || this.listaStatus == null || Object.keys(this.listaStatus).length == 0) {
            let msg = this.translate.instant('Sem dados para exibir.')
            this.snackBar.open(msg, '', { duration: 2000 });
            return;
          }
        },
        () => { });
  }

  ordenarListaStatusPorData() {
    this.listaStatus?.sort((indiceA, indiceB) => indiceA.periodo > indiceB.periodo ? -1 : 1);
  }

  groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      let currentResultValue = (result[currentValue[key]] = result[currentValue[key]] || {});
      currentResultValue.periodo = currentValue.periodo;
      currentResultValue.industria = currentValue.nomeIndustria;

      if (!(currentResultValue.movimentacoes)) {
        currentResultValue.movimentacoes = new Array<any>();
      }

      currentResultValue.movimentacoes.push(currentValue);
      return result;
    }, {});
  };

  selectionChanged(opcao) {
    this.listaFiltrada = this.listaStatus.map(x => ({ ...x }));

    switch (opcao.value.valor) {
      case 0:
        // só volta ao estado original
        break;
      case 1:
        this.listaFiltrada.forEach((periodo, index, arr) => {
          arr[index].movimentacoes = periodo.movimentacoes.filter(movimentacao => movimentacao.finalizado);
        });
        break;
      case 2:
        this.listaFiltrada.forEach((periodo, index, arr) => {
          arr[index].movimentacoes = periodo.movimentacoes.filter(movimentacao => !movimentacao.finalizado);
        });
        break;
    }
  }

  getValorPeriodo(data) {
    let mes = moment(data).format('MMMM');
    let ano = moment(data).format('YYYY');

    return this.translate.instant(mes) + ', ' + ano;
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }
}
