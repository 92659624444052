import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/comum/services/base-api.service';
@Injectable({
  providedIn: 'root'
})
export class UsuarioApiService extends BaseApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  getUsuariosIndustriaPorIndustria(id: number) {
    return this.get(`Usuario/UsuarioIndustria?idIndustria=${id}`);
  }
  inserirUsuarioIndustria(usuario) {
    return this.post(usuario, 'Usuario/UsuarioIndustria');
  }
  atualizarUsuarioIndustria(usuario) {
    return this.update(usuario, `Usuario/UsuarioIndustria/${usuario.idUsuario}`)
  }
  getUsuarios() {
    return this.get(`Usuario/Usuarios`);
  }
  getPerfis(){
    return this.get(`Perfil/PerfilUsuario`);
  }
  resetSenha(usuario){
    return this.update(usuario, `Usuario/RedefinirSenha`);
  }
}
