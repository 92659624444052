<soi-carregando></soi-carregando>

<div mat-dialog-title>
  {{ "Atualizar usuário da indústria" | translate }}
</div>
<div>
  <form [formGroup]="form" (ngSubmit)="enviar()">

    <mat-form-field>
      <mat-label>{{"E-mail Usuário" | translate}}</mat-label>
      <input matInput formControlName="email" required />
  </mat-form-field>

  <mat-slide-toggle formControlName="atualizarSenha" color="primary">
    {{ "Resetar Senha" | translate }}
    <mat-hint id="hint">{{ "Ao marcar, o usuário recebe uma nova senha via email" | translate }}</mat-hint>
  </mat-slide-toggle>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
  </button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="enviar()">
      <mat-icon>done</mat-icon>
  </button>
</div>
