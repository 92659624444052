<div class="container-fluid">
    <div class="row mt-3 mb-1">
        <div class="col-12">
            <soi-seletor-industria-distribuidora [mostrarOpcaoTodas]="true"
                (industriaSelecionada)="setIndustriaSelecionada($event)"
                (distribuidoraSelecionada)="setDistribuidoraSelecionada($event)">
            </soi-seletor-industria-distribuidora>
        </div>
    </div>
    <div class="row mt-3 mb-1">
        <div class="col-12">
            <button mat-raised-button color="primary" (click)="abrirForm()">
                {{ "Adicionar" | translate }}
            </button>

            <div class="lista-distribuidoras-container">
                <soi-mensagem-notificacao [mostrar]="distribuidorasComEmails?.length == 0"
                    mensagem="{{ 'Sem dados para exibir.' | translate }}" [fechavel]="false">

                </soi-mensagem-notificacao>

                <mat-accordion>
                    <mat-expansion-panel *ngFor="let distribuidora of distribuidorasComEmails">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="panel-title-conteudo">
                                    <span>{{ distribuidora.nomeCompleto }}</span>
                                    <span class="panel-title-subtitulo">
                                        {{ distribuidora.idSistemaExterno }} - {{ distribuidora.nomePais | translate }}
                                    </span>
                                </div>
                            </mat-panel-title>

                            <mat-panel-description>
                                <mat-icon>email</mat-icon> {{ distribuidora.emailsConfigurados.length }}
                            </mat-panel-description>

                        </mat-expansion-panel-header>

                        <div class="soi-table">
                            <table>
                                <tr>
                                    <th class="soi-table-cell-left">
                                        {{ "E-mail" | translate }}
                                    </th>
                                    <th class="soi-table-cell-right">
                                        <span class="cabecalho-dia-mes">
                                            {{ "Dia de envio" | translate }}
                                        </span>
                                    </th>
                                    <th class="soi-table-cell-right">
                                        {{ "Último envio" | translate }}
                                    </th>
                                </tr>
                                <tr *ngFor="let email of distribuidora.emailsConfigurados">
                                    <td class="soi-table-cell-left">
                                        <soi-patch-input value="{{ email.email }}" width="100%"
                                            (salvarValor)="atualizarEmail(email, distribuidora.idIndustriaDistribuidora, $event)"
                                            [validators]="['email']" mensagemErro="{{ 'E-mail inválido' | translate }}">
                                        </soi-patch-input>
                                    </td>

                                    <td class="soi-table-cell-right">
                                        <soi-patch-input value="{{ email.diaMesEnvio }}" type="number" textAlign="right"
                                            (salvarValor)="atualizarDiaMesEnvio(email, distribuidora.idIndustriaDistribuidora, $event)"
                                            [validators]="['diaMes']" mensagemErro="{{ 'Dia inválido' | translate }}">
                                        </soi-patch-input>
                                    </td>

                                    <td class="soi-table-cell-right">
                                        {{ getDataUltimoEnvio(email.dataUltimoEnvio) }}
                                    </td>

                                    <td class="soi-table-cell-right">
                                        <button mat-icon-button (click)="deletarEmail(email)" matTooltip="{{ 'Deletar' | translate }}">
                                            <mat-icon>delete_outline</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>