import { Component, OnInit } from '@angular/core';
import { UploadArquivoFeedbackService } from '../../services/upload-arquivo-feedback.service';

@Component({
  selector: 'soi-upload-arquivo-feedback',
  templateUrl: './upload-arquivo-feedback.component.html',
  styleUrls: ['./upload-arquivo-feedback.component.css']
})
export class UploadArquivoFeedbackComponent implements OnInit {
  mensagens: Array<object>;

  constructor(private uploadArquivoFeedbackService: UploadArquivoFeedbackService) { }

  ngOnInit(): void {
    this.getMensagens();
  }

  getMensagens(): void {
    this.uploadArquivoFeedbackService.mensagensFeedback$
      .subscribe(mensagens => this.mensagens = mensagens);
  }
}
