import { UsuarioIndustriaAtualizacaoFormComponent } from './../usuario-industria-atualizacao-form/usuario-industria-atualizacao-form.component';
import { UsuarioIndustriaFormComponent } from './../usuario-industria-form/usuario-industria-form.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { UsuarioApiService } from 'src/app/industria/services/usuario-api.service';
import { DistribuidoraApiService } from 'src/app/industria/services/distribuidora-api.service';
import { CarregamentoService } from 'src/app/comum/services/carregamento.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';


@Component({
  selector: 'soi-usuarios-industria',
  templateUrl: './usuario-industria.component.html',
  styleUrls: ['./usuario-industria.component.css']
})
export class UsuariosIndustriaComponent implements OnInit {
  usuarios: Array<any>;
  distribuidoras: Array<any>;
  industriaSelecionada: any;
  constructor(
    private usuarioApiService: UsuarioApiService,
    private distribuidoraApiService: DistribuidoraApiService,
    private dialog: MatDialog,
    private carregamentoService: CarregamentoService,
    private translate: TranslateService,
  ) { }
  ngOnInit(): void {
    this.getAllUsers();
  }
  getAllUsers() {
    this.usuarioApiService.getUsuarios()
      .subscribe(
        resposta => {
          this.usuarios = resposta.dados;
        },
        erro => {
        }
      )
  }
  getAllDistribuidora() {
    this.distribuidoraApiService.getAll()
      .subscribe(
        resposta => {
          this.distribuidoras = resposta;
        },
        erro => {
        }
      )
  }
  getUsuarios(industria) {
    this.industriaSelecionada = industria;
    this.usuarioApiService.getUsuariosIndustriaPorIndustria(industria.id)
      .subscribe(
        resposta => {
          this.usuarios = resposta.dados;
        },
        erro => {
        }
      )
  }
  abrirFormCadastro() {
    const dialogRef = this.dialog.open(UsuarioIndustriaFormComponent);
    dialogRef.afterClosed().subscribe(
      novoUsuario => {
        if (novoUsuario && novoUsuario?.industrias.findIndex(i => i.idIndustria == this.industriaSelecionada?.idIndustria) > -1) {
          this.usuarios.push(novoUsuario);
        }
      });
    dialogRef.afterClosed().subscribe(
      resposta => {
        if (resposta) {
          this.getAllUsers()
        }
      });
  }
  abrirFormAtualizacao(usuario) {
    const dialogRef = this.dialog.open(
      UsuarioIndustriaAtualizacaoFormComponent,
      { data: usuario }
    );
    dialogRef.afterClosed().subscribe(
      resposta => {
        if (resposta) {
          this.getAllUsers()
        }
      });
  }

  exportarExcel() {
    this.carregamentoService.mostrar();

    let dadosExcel = [];

    let industriasUser = "";

    this.usuarios.forEach(user => {
      user.industrias.forEach(ind => {
        industriasUser += ind.nomeCompleto + ' | '
      });

      dadosExcel.push({
        "codUsuario": user.idUsuario,
        "usuario": user.usuario,
        "email": user.email ? user.email : '',
        "perfil": user.perfil_usuario.descricao,
        "industria": industriasUser
      });

      industriasUser = "";
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dadosExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    //width excel colunas
    var wscols = [
      { width: 15 },
      { width: 25 },
      { width: 65 },
      { width: 15 },
      { width: 55 }
    ];

    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, 'Sell Out - Usuarios');

    const nomeArquivo: string = "UsuariosSellOut"

    XLSX.writeFile(wb,
      `${nomeArquivo}.xlsx`
    );

    this.carregamentoService.esconder();
  }
}

