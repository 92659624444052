<div mat-dialog-content>
    <p>{{ data.mensagem }}</p>
</div>
<div mat-dialog-actions class="acoes">
    <button mat-button (click)="onNoClick()">
        {{ "Não" | translate }}
    </button>

    <div class="spacer"></div>

    <button mat-raised-button color="primary" (click)="onYesClick()" cdkFocusInitial>
        {{ "Sim" | translate }}
    </button>
</div>