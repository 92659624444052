<div class="container-fluid">
  <div class="row mt-3 mb-1">
      <div class="col acoes">
          <div>
              <button mat-raised-button color="primary" (click)="exportarExcel()">
                  {{ "Exportar" | translate }}
              </button>
          </div>
          <div>
              <button mat-raised-button color="primary" (click)="abrirFormCadastro()">
                  {{ "Novo usuário" | translate }}
              </button>
          </div>
      </div>
  </div>

  <mat-list>
      <mat-list-item *ngFor="let usuario of usuarios" class="lista-cadastro-item">
          <div class="lista-cadastro-item-informacao">
              <div mat-line><b>{{ usuario.usuario }}</b> </div>
              <div mat-line id="center-screen"><pre class="tab">{{ usuario.email }}</pre> </div>
              <div mat-line class="lista-industrias">
                  <span *ngFor="let industria of usuario.industrias" class="industria">
                      {{ industria.nomeCompleto.toUpperCase() }}
                  </span>
              </div>
          </div>

          <div class="lista-cadastro-item-acoes">
              <button mat-icon-button matTooltip="{{ 'Editar' | translate }}" (click)="abrirFormAtualizacao(usuario)">
                  <mat-icon>edit</mat-icon>
              </button>
          </div>
      </mat-list-item>
  </mat-list>
</div>
