import { Component, SimpleChanges, OnInit } from '@angular/core';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { Rotas } from 'src/app/compartilhado/enums';

@Component({
  selector: 'soi-breadcrumb',
  providers: [Location, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {
  path: string;

  ngOnInit() {
    this.atualizarBreadCrumb();
  }

  constructor(private location: Location, private router: Router) {
    this.router.events.subscribe(() => {
      this.atualizarBreadCrumb();
    });
  }

  atualizarBreadCrumb() {
    // removendo primeira "/" do location.path() para comparação com o enum de Rotas
    // (o módulo de roteamento do Angular não permite passar a rota com "/" no início)
    let url = this.location.path().substring(1);

    switch (url) {
      case Rotas.CadastroProdutos:
        this.path = "Produtos";
        break;
      case Rotas.Movimentacoes:
        this.path = "Movimentações";
        break;
      case Rotas.StatusMovimentacoes:
        this.path = "Status de Movimentações";
        break;
      case Rotas.AssociacaoMixProdutos:
        this.path = "Associação de mix de produtos";
        break;
      case Rotas.PainelDistribuidor:
        this.path = "Painel do Distribuidor";
        break;
      case Rotas.EmailsAviso:
        this.path = "E-mails de Aviso";
        break;
      case Rotas.DashboardDistribuidor:
          this.path = "Dashboard do Distribuidor";
          break;
      case Rotas.Distribuidoras:
        this.path = "Distribuidoras";
        break;
      case Rotas.CadastroUsuarios:
      this.path = "Usuários / Usuários da Indústria";
      break;
      default:
        this.path = "";
        break;
    }
  }
}
