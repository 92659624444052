import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'soi-seletor-industria',
  templateUrl: './seletor-industria.component.html',
  styleUrls: ['./seletor-industria.component.css']
})
export class SeletorIndustriaComponent implements OnInit {
  industrias: Array<any> = new Array<any>();

  industria: any;

  @Output() industriaSelecionada = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.industrias = JSON.parse(localStorage.getItem('industriasUsuario'));
    if (this.industrias == null) {
      this.industrias = [];
    }
    this.setValorSeletores();
  }

  setValorSeletores() {
    if (this.industrias.length == 1) {
      this.industria = this.industrias[0];

      this.emitirIndustria();
    }
  }

  emitirIndustria() {
    this.industriaSelecionada.emit(this.industria);
  }
}
