import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Router } from '@angular/router';

import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let tokenWebApi = localStorage.getItem('tokenWebApi');
    if (tokenWebApi) {
      req = req.clone({
        setHeaders: {
          // 'Content-Type' : 'application/json; charset=utf-8',
          'Authorization': `Bearer ${tokenWebApi}`
        }
      });
    }

    return next.handle(req)
    .pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError(err => {
        if (this.router.url !== '/login' && err.status === 401) {
          this.snackBar.open(this.translate.instant('Seu acesso expirou, realize o login novamente.'), '', { duration: 2000 });

          this.router.navigate(['/logout']);
        }
        else if (this.router.url == '/login' && err.status === 401) {
          this.snackBar.open(this.translate.instant('Usuário ou senha incorretos.'));
        }
        else if (err.status === 403) {
          this.snackBar.open(this.translate.instant('Você não tem permissão para esta ação!'));
        }
        else {
          this.snackBar.open(this.translate.instant('Falha no processamento, entre em contato com o suporte.'), '', { duration: 2000 });
        }

        throw err;
      })
    );
  }
}
