// Imports do Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppRoutingModule } from '../app-routing.module';

// Tradução
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TraducaoNaoExistenteHandler } from '../compartilhado/TraducaoNaoExistenteHandler';
import { MissingTranslationHandler } from '@ngx-translate/core';

import { AngularMaterialModule } from '../angular-material/angular-material.module';

// Components do módulo
import { CarregandoComponent } from './components/carregando/carregando.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { PaginaNaoEncontradaComponent } from './components/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { PaginaComErroComponent } from './components/pagina-com-erro/pagina-com-erro.component';
import { SeletorLinguagemComponent } from './components/seletor-linguagem/seletor-linguagem.component';
import { SeletorMesAnoComponent, MY_FORMATS } from './components/seletor-mes-ano/seletor-mes-ano.component';
import { SeletorIndustriaComponent } from './components/seletor-industria/seletor-industria.component';
import { SeletorIndustriaDistribuidoraComponent } from './components/seletor-industria-distribuidora/seletor-industria-distribuidora.component';
import { SeletorIndustriaPaisDistribuidoraComponent } from './components/seletor-industria-pais-distribuidora/seletor-industria-pais-distribuidora.component';
import { ConfirmacaoDialogComponent } from './components/confirmacao-dialog/confirmacao-dialog.component';
import { MensagemNotificacaoComponent } from './components/mensagem-notificacao/mensagem-notificacao.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { UploadArquivoComponent } from './components/upload-arquivo/upload-arquivo.component';
import { UploadArquivoFeedbackComponent } from './components/upload-arquivo-feedback/upload-arquivo-feedback.component';
import { PatchInputComponent } from './components/patch-input/patch-input.component';
import { DashboardDistribuidorComponent } from './components/dashboard-distribuidor/dashboard-distribuidor.component';
import { ListaIndustriaChipComponent } from './components/lista-industria-chip/lista-industria-chip.component';
import { SeletorPaisComponent } from './components/seletor-pais/seletor-pais.component';

import { ThrottleClickDirective } from './directives/throttle-click.directive';
import { InputApenasNumerosInteirosDirective } from './directives/input-apenas-numeros-inteiros.directive';
import { StatusChipComponent } from './components/status-chip/status-chip.component';


@NgModule({
  declarations: [
    CarregandoComponent,
    LoginComponent,
    LogoutComponent,
    MenuComponent,
    PaginaNaoEncontradaComponent,
    PaginaComErroComponent,
    SeletorLinguagemComponent,
    SeletorMesAnoComponent,
    SeletorIndustriaComponent,
    SeletorIndustriaDistribuidoraComponent,
    SeletorIndustriaPaisDistribuidoraComponent,
    ConfirmacaoDialogComponent,
    MensagemNotificacaoComponent,
    BreadcrumbComponent,
    UploadArquivoComponent,
    UploadArquivoFeedbackComponent,
    PatchInputComponent,
    ThrottleClickDirective,
    InputApenasNumerosInteirosDirective,
    DashboardDistribuidorComponent,
    StatusChipComponent,
    ListaIndustriaChipComponent,
    SeletorPaisComponent,
  ],
  imports: [
    AngularMaterialModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler, useClass: TraducaoNaoExistenteHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    AngularMaterialModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CarregandoComponent,
    LoginComponent,
    LogoutComponent,
    MenuComponent,
    PaginaNaoEncontradaComponent,
    PaginaComErroComponent,
    PatchInputComponent,
    SeletorLinguagemComponent,
    SeletorMesAnoComponent,
    SeletorIndustriaComponent,
    SeletorIndustriaDistribuidoraComponent,
    SeletorIndustriaPaisDistribuidoraComponent,
    MensagemNotificacaoComponent,
    UploadArquivoComponent,
    UploadArquivoFeedbackComponent,
    ThrottleClickDirective,
    InputApenasNumerosInteirosDirective,
    StatusChipComponent,
    ListaIndustriaChipComponent,
    SeletorPaisComponent,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    }
  ]
})
export class ComumModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
