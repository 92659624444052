import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/comum/services/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class DistribuidoraDashboardApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getDistribuidorasDashboard(idDistribuidora: number) {
    return this.get(`DistribuidoraDashboard/GetAcessoDistribuidoraBi/${idDistribuidora}`);
  }
  
}
