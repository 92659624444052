import { AlertService } from './../../../../comum/services/alert.service';
import { DistribuidoraApiService } from 'src/app/industria/services/distribuidora-api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IndustriaApiService } from 'src/app/comum/services/industria-api.service';
import { keys } from 'highcharts';

const promiseResolvida = Promise.resolve(null);
@Component({
  selector: 'soi-distribuidora-form',
  templateUrl: './distribuidora-form.component.html',
  styleUrls: ['./distribuidora-form.component.css']
})
export class DistribuidoraFormComponent implements OnInit {
  industrias: Array<any>;

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private distribuidoraService: DistribuidoraApiService,
    private industriaApiService: IndustriaApiService,
    @Inject(MAT_DIALOG_DATA) public distribuidoraExistente: any,
    private alert: AlertService
  ) { }

  ngOnInit(): void {
    this.carregarIndustrias();
    this.iniciarForm();
  }

  iniciarForm() {
    this.form = this.formBuilder.group({
      idDistribuidora: [this.distribuidoraExistente?.idDistribuidora ?? 0],
      nomeCompleto: [this.distribuidoraExistente?.nomeCompleto, [Validators.required]],
      nroDocumento: [this.distribuidoraExistente?.nroDocumento],
      idSistemaExterno: [this.distribuidoraExistente?.idSistemaExterno, [Validators.required]],
      industrias: [this.distribuidoraExistente?.industrias, Validators.required],
      ativa: [this.distribuidoraExistente?.ativa ?? true]
    });
  }

  carregarIndustrias() {
    this.industriaApiService.getIndustrias()
      .subscribe(
        resposta => {
          this.industrias = resposta.dados?.sort((a, b) => a.nomeCompleto > b.nomeCompleto ? 1 : -1);
        },
        erro => {

        });
  }

  compararIndustrias(industriaA: any, industriaB: any) {
    if (industriaA?.idIndustria == industriaB?.idIndustria) {
      industriaA.opcaoDesabilitada = true;
      return true;
    }
    else {
      return false;
    }
  }

  adicionarPaisControl(paisFormGroup) {
    promiseResolvida.then(() => {
      this.form.addControl('pais', paisFormGroup.controls['pais']);
    });
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  enviar() {
    if (this.distribuidoraExistente) {
      if(!Array.isArray(this.form.value.industrias)){
        let array = [];
        array.push(this.form.value.industrias);
        this.form.value.industrias = [];
        this.form.value.industrias = array;
      }
      this.atualizar();
    }
    else {
      if(!Array.isArray(this.form.value.industrias)){
        let array = [];
        array.push(this.form.value.industrias);
        this.form.value.industrias = [];
        this.form.value.industrias = array;
      }
      this.inserir();
    }
  }

  inserir() {
    this.distribuidoraService.inserir(this.form.value)
      .subscribe(
        resposta => {
          if (resposta.sucesso) {
            this.alert.sucesso('Cadastro realizado com sucesso');
            this.dialogRef.close(resposta.dados);
          }
        },
        erro => {
          this.alert.erro('Erro ao cadastrar');
        }
      );
  }

  atualizar() {
    this.distribuidoraService.atualizar(this.form.value)
      .subscribe(
        resposta => {
          if (resposta.sucesso) {
            this.alert.sucesso('Atualização realizada com sucesso');
            this.dialogRef.close(resposta.dados);
          }
        },
        erro => {
          this.alert.erro('Erro ao atualizar');
        }
      );
  }
}
