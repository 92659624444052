import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnvioEmailsAvisoFormComponent } from '../envio-emails-aviso-form/envio-emails-aviso-form.component';
import { EmailAvisoApiService } from 'src/app/industria/services/email-aviso-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';

@Component({
  selector: 'soi-envio-emails-aviso-lista',
  templateUrl: './envio-emails-aviso-lista.component.html',
  styleUrls: ['./envio-emails-aviso-lista.component.css']
})
export class EnvioEmailsAvisoListaComponent {
  industriaSelecionada: any;
  distribuidoraSelecionada: any;
  distribuidorasComEmails: Array<any>;

  constructor(
    private dialog: MatDialog,
    private envioEmailApiService: EmailAvisoApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngAfterViewInit(): void {
    this.buscarEmailsDaIndustria();
  }

  setIndustriaSelecionada(industria) {
    this.industriaSelecionada = industria;
  }

  setDistribuidoraSelecionada(distribuidora) {
    this.distribuidoraSelecionada = distribuidora;
    this.buscarEmailsDaIndustria();
  }

  buscarEmailsDaIndustria() {
    // o componente soi-seletor-industria-distribuidora auto-seleciona a indústria, caso seja única
    if (this.industriaSelecionada) {
      this.envioEmailApiService.getEmailsPorIndustria(this.industriaSelecionada.id, this.distribuidoraSelecionada?.id | 0)
        .subscribe(
          resposta => {
            this.exibirMensagem(resposta);
            if (resposta.sucesso == false) {
              return;
            }
            this.distribuidorasComEmails = this.getListaDistribuidorasComEmails(resposta.dados, 'idDistribuidora');
            this.ordernarListaPrincipal();
          }
        )
    }
  }

  abrirForm(): void {
    const dialogRef = this.dialog.open(EnvioEmailsAvisoFormComponent);

    dialogRef.afterClosed()
      .subscribe(
        novoEmail => {
          if (novoEmail) {
            this.inserirNovoEmailNaLista(novoEmail);
          }
        }
      );
  }

  inserirNovoEmailNaLista(novoEmail: any) {
    let distribuidora = this.distribuidorasComEmails.find(distribuidora => distribuidora.idIndustriaDistribuidora == novoEmail.idIndustriaDistribuidora);

    if (distribuidora) {
      distribuidora.emailsConfigurados.push(this.getObjetoEmailConfigurado(novoEmail));
    }
    else {
      if (!Array.isArray(novoEmail)) {
        novoEmail = new Array(novoEmail);
      }

      this.distribuidorasComEmails.push(this.getListaDistribuidorasComEmails(novoEmail, 'idDistribuidora')[0]);
      this.ordernarListaPrincipal();
    }

    this.snackBar.open(this.translate.instant(`E-mail de aviso cadastrado`));
  }

  ordernarListaPrincipal() {
    this.distribuidorasComEmails.sort((a, b) => a.nomeCompleto > b.nomeCompleto ? 1 : -1);
  }

  getObjetoEmailConfigurado(emailConfigurado) {
    return {
      idEmailEnvio: emailConfigurado.idEmailEnvio,
      email: emailConfigurado.email,
      diaMesEnvio: emailConfigurado.diaMesEnvio,
      dataUltimoEnvio: emailConfigurado.dataUltimoEnvio
    }
  }

  getListaDistribuidorasComEmails = (array, key) => {
    return Object.values(array.reduce((result, currentValue) => {
      let currentResultValue = (result[currentValue[key]] = result[currentValue[key]] || {});
      currentResultValue.idDistribuidora = currentValue.idDistribuidora;
      currentResultValue.nomeCompleto = currentValue.nomeCompleto;
      currentResultValue.nomePais = currentValue.nomePais;
      currentResultValue.siglaPais = currentValue.siglaPais;
      currentResultValue.idSistemaExterno = currentValue.idSistemaExterno;
      currentResultValue.idIndustriaDistribuidora = currentValue.idIndustriaDistribuidora;

      if (!(currentResultValue.emailsConfigurados)) {
        currentResultValue.emailsConfigurados = new Array<any>();
      }

      currentResultValue.emailsConfigurados.push(this.getObjetoEmailConfigurado(currentValue));

      return result;
    }, {}));
  };

  getDataUltimoEnvio(data) {
    if (!data) {
      return this.translate.instant("Não registrado");
    }

    return moment(data).format('MMMM Do YYYY, h:mm:ss a');
  }

  deletarEmail(email) {
    this.envioEmailApiService.deletarEmail(email)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }

          let distribuidora = this.distribuidorasComEmails.find(distribuidora => distribuidora.emailsConfigurados.indexOf(email) > -1);

          if (distribuidora) {
            distribuidora.emailsConfigurados.splice(distribuidora.emailsConfigurados.indexOf(email), 1);
          }

          if (distribuidora.emailsConfigurados.length == 0) {
            this.distribuidorasComEmails.splice(this.distribuidorasComEmails.indexOf(distribuidora), 1)
          }

          this.snackBar.open(this.translate.instant(`E-mail de aviso removido`));
        },
        erro => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'));
        }
      )
  }

  atualizarEmail(email, idIndustriaDistribuidora, valorAlterado) {
    email.email = valorAlterado;
    email.idIndustriaDistribuidora = idIndustriaDistribuidora;

    this.envioEmailApiService.atualizarEmail(email)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }

          this.snackBar.open(this.translate.instant(`E-mail de aviso atualizado`));
        },
        erro => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'));
        }
      )
  }

  atualizarDiaMesEnvio(email, idIndustriaDistribuidora, valorAlterado) {
    email.diaMesEnvio = parseInt(valorAlterado);
    email.idIndustriaDistribuidora = idIndustriaDistribuidora;

    this.envioEmailApiService.atualizarEmail(email)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }
          this.snackBar.open(this.translate.instant(`E-mail de aviso atualizado`));
        },
        erro => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'));
        }
      )
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }
}
