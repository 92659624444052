import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { LoginService } from 'src/app/comum/services/login.service';
import { SessionService } from '../../services/session.service';
import { environment } from 'src/environments/environment';
import { CarregamentoService } from '../../services/carregamento.service';
import { UsuarioApiService } from 'src/app/industria/services/usuario-api.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: "soi-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit, OnDestroy {
  hide = true;
  loginForm: any;
  error: string = '';
  mostrarEmail = false;
  msgResetSucesso: string = '';

  exibirDesenvolvidoPor: boolean;
  classeBackgroundLogin: string;
  cliente: string;

  constructor(
    private usuarioApiService: UsuarioApiService,
    private carregamentoService: CarregamentoService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private session: SessionService,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {

  }

  /**
   * Acesso facilitado ao formulário
   */
  get f() {
    return this.loginForm.controls;
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      usuario: ["", Validators.required],
      email: [""], //Email para reset de senha
      senha: ["", Validators.required]
    });

    if (this.session.estaLogado) {
      this.loginService.redirecionarAposLogin();
    }
    this.cliente = environment.client ? environment.client : 'Target';

    switch (this.cliente) {
      case 'Kin':
        this.classeBackgroundLogin = "kin";
        this.exibirDesenvolvidoPor = true;
        break;
      default:
        this.classeBackgroundLogin = "target";
        this.exibirDesenvolvidoPor = false;
    }

    this.renderer.addClass(document.body, 'login');
    this.renderer.addClass(document.body, this.classeBackgroundLogin);

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login');
  }

  /**
   * Envia a tentativa de login, lida com o resultado
   */
  async enviaLogin() {
    if (this.loginForm.invalid) {
      return;
    }

    this.loginService.loginSimples(this.f.usuario.value, this.f.senha.value)
      .subscribe((retornoDados) => {

        if (retornoDados == null || retornoDados == "") {
          this.error = "Falha no login";

          return;
        }

        this.session.setItem('dadosUsuario', JSON.stringify(retornoDados));
        this.session.setItem('tokenWebApi', retornoDados['token'])
        this.session.setIndustriasUsuario();
        //this.loginService.usuarioAutenticado(true);
        this.session.usuarioAutenticado(true);
        this.loginService.redirecionarAposLogin();
      }, error => {
        if (error.status == 400) {
          this.error = 'Usuário ou senha incorretos.';
        } else {
          this.error = 'Não foi possível entrar. Acione o suporte ou tente mais tarde.';
        }
      }, () => {
      }
      );
  }

  habilitarCamposResetSenha() {
    this.mostrarEmail = !this.mostrarEmail;

    if (!this.mostrarEmail) {
      //clicou "voltar, apaga mensagens"
      this.msgResetSucesso = '';
      this.error = '';
    }
  }

  resetarSenha() {
    this.carregamentoService.mostrar();

    this.usuarioApiService.resetSenha(this.loginForm.value)
      .subscribe(
        retornoDados => {
          if (retornoDados.sucesso == null || retornoDados == "") {
            this.error = this.translate.instant('Erro ao resetar senha. Por favor, contacte os administradores.');
            this.msgResetSucesso = '';
          } else {
            this.msgResetSucesso = this.translate.instant("Sua senha foi atualizada com sucesso! Por favor, verifique o seu email.");
            this.error = '';
          }
        },
        error => {
          this.error = this.translate.instant('Erro ao resetar senha. Por favor, contacte os administradores.');
          this.msgResetSucesso = '';
        });
  }

}
