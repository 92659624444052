import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { Router } from '@angular/router';

@Component({
  selector: "soi-logout",
  template: ""
})
export class LogoutComponent implements OnInit {
  hide = true;
  logoutForm: any;
  error: string;

  exibirDesenvolvidoPor: boolean;
  classeBackgroundLogout: string;
  cliente: string;

  constructor(
    private session: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.session.limpaSessao();
    this.router.navigate(['/login']);
  }
}
