import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PerfilUsuario } from 'src/app/compartilhado/enums';
import { SessionService } from '../../services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'soi-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent // implements OnInit
{
  usuarioLogado: boolean;
  permitidoPainelDistribuidor: boolean = false;
  permitidoCadastros: boolean = false;
  permitidoConsultas: boolean = false;
  permitidoConfiguracoes: boolean = false;
  loginSubscription: Subscription;
  mostrarSeletorIdioma: boolean = false;
  mostrarMenu: boolean = false;

  cliente: string;
  corMenu: string;

  constructor(
    private router: Router,
    private session: SessionService
  ) {
    this.loginSubscription = this.session.usuarioLogado$
      .subscribe(() => { this.definirPermissoes(); });

    this.definirPermissoes();
  }

  definirPermissoes(): void {
    if (!this.session.estaLogado) {
      this.usuarioLogado = false;
      this.mostrarMenu = false;
      return;
    }
    this.cliente = environment.client ? environment.client : 'Target';

    switch (this.cliente) {
      case 'Kin':
        this.corMenu = 'white';
        break;
      default:
        this.corMenu = 'accent';
    }

    let perfil: PerfilUsuario = this.session.perfilUsuario.id;

    this.usuarioLogado = true;
    this.mostrarMenu = true;
    this.permitidoPainelDistribuidor = true; // todos têm acesso
    this.permitidoCadastros = (perfil == PerfilUsuario.Administrador) || (perfil == PerfilUsuario.Industria);
    this.permitidoConsultas = (perfil == PerfilUsuario.Administrador) || (perfil == PerfilUsuario.Industria);
    this.permitidoConfiguracoes = (perfil == PerfilUsuario.Administrador) || (perfil == PerfilUsuario.Industria);
    this.mostrarSeletorIdioma = perfil == PerfilUsuario.Administrador;
  }

  async logout() {
    this.session.limpaSessao();
    this.router.navigate(['/login']);
  }
}
