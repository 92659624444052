import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  sucesso(mensagem: string, parametros: string[] = null) {
    this.snackBar.open(this.translate.instant(mensagem, parametros), 'OK', { panelClass: 'alert-sucesso' });
  }

  erro(mensagem: string, parametros: string[] = null) {
    this.snackBar.open(this.translate.instant(mensagem, parametros), 'OK', { panelClass: 'alert-erro' });
  }

  aviso(mensagem: string, parametros: string[] = null) {
    this.snackBar.open(this.translate.instant(mensagem, parametros), 'OK', { panelClass: 'alert-aviso' });
  }

  mensagemPadronizada(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;

    if (retorno.sucesso) {
      this.sucesso(tipoMensagem, parametros);
    }
    else {
      this.erro(tipoMensagem, parametros);
    }
  }
}
