<div [formGroup]="form">
  <mat-form-field>
      <mat-label>{{ "País" | translate }}</mat-label>
      <input type="text" matInput formControlName="pais" [matAutocomplete]="auto" required>
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="mostrarPorNome"
          (optionSelected)="selecionarPais($event.option.value)">
          <mat-option *ngFor="let pais of paisesFiltrados | async" [value]="pais">
              {{ pais.nome | translate }}
          </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.controls['pais'].hasError('opcaoNaoSelecionada')">
          {{ "Selecione uma das opções disponíveis" | translate }}

      </mat-error>
  </mat-form-field>
</div>
