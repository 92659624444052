import { Component, Input, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { CarregamentoService } from '../../services/carregamento.service';

@Component({
  selector: 'soi-carregando',
  templateUrl: './carregando.component.html',
  styleUrls: ['./carregando.component.css']
})
export class CarregandoComponent {
  @Input() value: number = 100;
  @Input() diameter: number = 100;
  @Input() mode: string = "indeterminate";
  @Input() strokeWidth: number = 10;
  @Input() color: string = "primary";
  display: Subject<boolean> = this.carregamento.carregando;

  constructor(private carregamento: CarregamentoService) { }
}
