import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DistribuidoraApiService } from 'src/app/industria/services/distribuidora-api.service';
import { DistribuidoraFormComponent } from '../distribuidora-form/distribuidora-form.component';
@Component({
  selector: 'soi-distribuidoras',
  templateUrl: './distribuidoras.component.html',
  styleUrls: ['./distribuidoras.component.css']
})
export class DistribuidorasComponent implements OnInit {
  distribuidoras: Array<any>;
  industriaSelecionada: any;
  constructor(
    private distribuidoraService: DistribuidoraApiService,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {
  }
  getDistribuidorasPorIndustria(industria) {
    this.distribuidoraService.getDistribuidorasIndustria(industria.id)
      .subscribe(
        resposta => {
          this.industriaSelecionada = industria;
          this.distribuidoras = resposta.dados;
          this.ordenarPorNome();
        },
        erro => {
        }
      );
  }
  ordenarPorNome() {
    this.distribuidoras?.sort((a, b) => a.nomeCompleto.toLowerCase() > b.nomeCompleto.toLowerCase() ? 1 : -1);
  }
  abrirForm(distribuidora: any = null): void {
    const dialogRef = this.dialog.open(
      DistribuidoraFormComponent,
      { data: distribuidora }
    );
    dialogRef.afterClosed()
      .subscribe(
        novaDistribuidora => {
          if (novaDistribuidora) {
            this.atualizarListaDistribuidoras(novaDistribuidora);
          }
        }
      );
  }
  atualizarListaDistribuidoras(distribuidora: any) {
    if (distribuidora) {
      let index = this.distribuidoras?.findIndex(atual => atual.idDistribuidora == distribuidora.idDistribuidora);
      if (index > -1) {
        this.distribuidoras[index] = distribuidora;
      }
      else {
        if (!this.distribuidoras) {
          this.distribuidoras = new Array<any>();
        }
        this.distribuidoras.push(distribuidora);
      }
      this.ordenarPorNome();
    }
  }
}
