import { NgModule } from '@angular/core';
import { ComumModule } from '../comum/comum.module';

// Componentes do módulo
import { EntradaMovimentacoesComponent } from './components/entrada-movimentacoes/entrada-movimentacoes.component';


@NgModule({
  declarations: [
    EntradaMovimentacoesComponent
  ],
  imports: [
    ComumModule
  ],
  exports: [
    EntradaMovimentacoesComponent
  ]
})
export class DistribuidoraModule { }
