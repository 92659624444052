import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProdutoApiService } from 'src/app/industria/services/produto-api.service';

@Component({
  selector: 'soi-produto-form',
  templateUrl: './produto-form.component.html',
  styleUrls: ['./produto-form.component.css']
})
export class ProdutoFormComponent implements OnInit {
  @Input() industriaSelecionada: any;

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private produtoService: ProdutoApiService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public produtoExistente: any
  ) { }

  ngOnInit(): void {
    this.iniciarForm();
  }

  iniciarForm() {
    this.form = this.formBuilder.group({
      idProduto: [this.produtoExistente?.idProduto ?? 0],
      descricao: [this.produtoExistente?.descricao, [Validators.required]],
      codigoProdutoIndustria: [this.produtoExistente?.codigoProdutoIndustria],
      codigoProdutoAgrupado: [this.produtoExistente?.codigoProdutoAgrupado],
      descricaoIngles: [this.produtoExistente?.linguagens?.ingles ?? ""],
      descricaoEspanhol: [this.produtoExistente?.linguagens?.espanhol ?? ""],
      descricaoFrances: [this.produtoExistente?.linguagens?.frances ?? ""],
      idIndustria: [this.industriaSelecionada.id, [Validators.required]]
    });
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  enviar() {
    if (this.produtoExistente) {
      this.atualizar();
    }
    else {
      this.inserir();
    }
  }

  inserir() {
    this.produtoService.inserir(this.montarObjeto(this.form.value))
      .subscribe(
        resposta => {
          if (resposta.sucesso) {
            this.snackBar.open(this.translate.instant('Cadastro realizado com sucesso'), '', { duration: 2000 });
            this.dialogRef.close(resposta.dados);
          }
        },
        erro => {
          this.snackBar.open(this.translate.instant('Erro ao cadastrar'), '', { duration: 2000 });
          this.tratarErro(erro);
        }
      );
  }

  atualizar() {
    this.produtoService.atualizar(this.montarObjeto(this.form.value))
      .subscribe(
        resposta => {
          if (resposta.sucesso) {
            this.snackBar.open(this.translate.instant('Atualização realizada com sucesso'), '', { duration: 2000 });
            this.dialogRef.close(resposta.dados);
          }
        },
        erro => {
          this.snackBar.open(this.translate.instant('Erro ao atualizar'), '', { duration: 2000 });
          this.tratarErro(erro);
        }
      );
  }

  tratarErro(erro: string) {
    if (erro.includes('Error Code: 409')) {
      this.form.controls['codigoBarras'].setErrors({ 'duplicado': true });
    }
  }

  montarObjeto(produto) {
    let objeto = {
      idProduto: produto.idProduto,
      descricao: produto.descricao,
      codigoProdutoIndustria: produto.codigoProdutoIndustria,
      codigoProdutoAgrupado: produto.codigoProdutoAgrupado,
      idIndustria: produto.idIndustria,
      linguagens: {
        ingles: produto.descricaoIngles,
        espanhol: produto.descricaoEspanhol,
        frances: produto.descricaoFrances
      }
    };

    return objeto;
  }

}
