<div class="container-login">
  <div class="login">
    <!-- <div id='logo_intro'>
      <img src='./assets/img/logo_intro.png'>
    </div> -->
    <div class='borda'></div>
    <mat-card class="card-padrao conteudo">
      <div class="login-intro">
        <div [ngSwitch]="cliente" class="card-header-image">
          <!-- Kin -->
          <img *ngSwitchCase="'Kin'" class="logo" src="../../../../assets/img/Kin.png">

          <!-- Padrão Target -->
          <img *ngSwitchDefault src="../../../../assets/img/tgt-logo.svg" width="180px">
        </div>
        <h1 class='mensagem'>
          {{ "Bem-vindo ao Sell-Out Indústria" | translate }}
        </h1>
      </div>
      <form [formGroup]="loginForm" class="form-login">
        <mat-card-content class="conteudo-login">
          <div class="form-login-container">
            <mat-form-field appearance="outline">
              <mat-label>{{ "Usuário" | translate }}</mat-label>
              <input matInput formControlName="usuario" />
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!mostrarEmail">
              <mat-label>{{ "Senha" | translate }}</mat-label>
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="senha" />
              <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
            </mat-form-field>

            <!-- Botões de reset de Senha -->
            <mat-form-field appearance="outline" *ngIf="mostrarEmail">
              <mat-label>{{ "E-mail" | translate }}</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>

            <!-- Botões de reset de Senha -->
            <button mat-icon-button matTooltip="{{ 'Resetar Senha' | translate }}" (click)="habilitarCamposResetSenha()"
              *ngIf="!mostrarEmail">{{ "Esqueceu a senha?" | translate }}</button>
            <button mat-icon-button matTooltip="{{ 'Voltar' | translate }}" (click)="habilitarCamposResetSenha()"
              *ngIf="mostrarEmail">{{ "Voltar" | translate }}</button>

            <p *ngIf="error != ''" class="login-error">
              {{ error | translate }}
            </p>

            <p *ngIf="msgResetSucesso != ''" class="reset-senha-success">
              {{ msgResetSucesso | translate }}
            </p>
          </div>
        </mat-card-content>

        <mat-card-actions class="acoes-login">
          <button type="submit" mat-raised-button (click)="enviaLogin()" color="primary" *ngIf="!mostrarEmail">
            {{ "Entrar" | translate }}
          </button>
          <!-- Botões de reset de Senha -->
          <button type="submit" mat-raised-button (click)="resetarSenha()" color="primary" *ngIf="mostrarEmail">
            {{ "Resetar Senha" | translate }}
          </button>
        </mat-card-actions>

      </form>
    </mat-card>
  </div>
  <div *ngIf="exibirDesenvolvidoPor" class="target">
    <span> {{ "Desenvolvido por" | translate }} </span>
    <a href="http://www.targetsistemas.com.br" target="_blank"><img src="../../../../assets/img/tgt_logo_white.png"></a>
  </div>
</div>