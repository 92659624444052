import { UsuarioApiService } from './../../../services/usuario-api.service';
import { IndustriaApiService } from './../../../../comum/services/industria-api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CarregamentoService } from 'src/app/comum/services/carregamento.service';

@Component({
  selector: 'soi-usuario-industria-atualizacao-form',
  templateUrl: './usuario-industria-atualizacao-form.component.html',
  styleUrls: ['./usuario-industria-atualizacao-form.component.css']
})
export class UsuarioIndustriaAtualizacaoFormComponent implements OnInit {
  form: FormGroup;
  industrias: Array<any>;
  mostrarSenha: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public usuario: any,
    private industriaApiService: IndustriaApiService,
    private usuarioApiService: UsuarioApiService,
    private carregamentoService: CarregamentoService,
  ) { }
  ngOnInit(): void {
    this.carregarIndustrias();
    this.iniciarForm();
  }
  carregarIndustrias() {
    this.carregamentoService.mostrar();
    this.industriaApiService.getIndustrias()
      .subscribe(
        resposta => {
          this.industrias = resposta.dados?.sort((a, b) => a.nomeCompleto > b.nomeCompleto ? 1 : -1);
          this.carregamentoService.esconder();
        },
        erro => {
          this.carregamentoService.esconder();
        });
  }
  iniciarForm() {
    this.form = this.formBuilder.group({
      idUsuario: [this.usuario.idUsuario, [Validators.required]],
      email: [this.usuario.email, [Validators.required]],
      atualizarSenha: [this.usuario.atualizarSenha, [Validators.required]]
    });
  }
  compararIndustrias(industriaA: any, industriaB: any) {
    if (industriaA?.idIndustria == industriaB?.idIndustria) {
      return true;
    }
    else {
      return false;
    }
  }
  cancelar() {
    this.dialogRef.close(false);
  }
  enviar() {
    this.atualizar();
  }
  atualizar() {
    this.carregamentoService.mostrar();

    this.usuarioApiService.atualizarUsuarioIndustria(this.form.value)
      .subscribe(
        resposta => {
          let usuarioAtualizado = resposta.dados
          this.usuario.ativo = usuarioAtualizado.ativo;
          this.usuario.industrias = usuarioAtualizado.industrias;
          this.snackBar.open('Usuário atualizado');
          this.dialogRef.close(true);
          this.carregamentoService.esconder();
        },
        erro => {
          this.carregamentoService.esconder();
        });
  }
  consolao(){
    console.log('hahah');
  }
}
