<div class="seletor-flex">
  <mat-form-field appearance="outline" class="filtro-periodo">
    <mat-label>{{"Período" | translate}}</mat-label>

    <input readonly matInput [matDatepicker]="dp" [formControl]="mesAno"
      [min]="minDate" [max]="maxDate"
    >
    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)"
      (monthSelected)="chosenMonthHandler($event, dp)">
    </mat-datepicker>
  </mat-form-field>
  <mat-slide-toggle class="toggle-todo-periodo" *ngIf="permitirTodoPeriodo"
    [checked]="false" matSuffix
    [formControl]="todoPeriodo" (change)="definirPeriodoTodo()"
  >
    {{ "Todos os meses" | translate }}
  </mat-slide-toggle>
</div>
