<div class="container-fluid">
  <div class="row mt-3 mb-1">
    <div class="col-12 seletor-flex">
      <soi-seletor-industria
        (industriaSelecionada)="getProdutosPorIndustria($event)">
      </soi-seletor-industria>

      <div class="col acoes" *ngIf="industriaSelecionada">
        <div>
          <button mat-raised-button color="primary" (click)="abrirForm()">
            {{ "Novo produto" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <soi-mensagem-notificacao
    [mostrar]="!industriaSelecionada"
    [fechavel]="false"
    mensagem="{{ 'Selecione uma indústria.' | translate }}">
  </soi-mensagem-notificacao>

  <soi-mensagem-notificacao
    [mostrar]="industriaSelecionada && produtos?.length == 0"
    [fechavel]="false"
    mensagem="{{ 'Não foram encontrados produtos cadastrados para essa indústria.' | translate }}">
  </soi-mensagem-notificacao>

  <div class="row">
    <div class="col">
      <!-- utilizando hidden, pois o *ngIf quebra o paginador -->
      <div [hidden]="!tabelaProdutosRenderizada" class="soi-table-container-sm">
        <mat-form-field class="w-100 my-2">
          <mat-label>{{ "Filtrar" | translate }}</mat-label>
          <input matInput (keyup)="filtrarProdutos($event.target.value)"
            placeholder="{{ 'Digite o código, descrição ou código do grupo' | translate }}">
        </mat-form-field>

        <div class="soi-table mat-elevation-z8">
          <table mat-table [dataSource]="dataSourceTabelaProdutos" matSort>
            <tr mat-header-row *matHeaderRowDef="colunasTabelaProdutos"></tr>
            <tr mat-row *matRowDef="let row; columns: colunasTabelaProdutos;"></tr>

            <ng-container matColumnDef="descricao">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "Descrição" | translate }}
              </th>
              <td mat-cell *matCellDef="let produto">
                <div>{{ produto.descricao }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="codigoProdutoIndustria">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ "Código" | translate }}
                </th>
                <td mat-cell *matCellDef="let produto">
                  <div>{{ produto.codigoProdutoIndustria }}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef="codigoProdutoAgrupado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ "Código do Grupo" | translate }}
              </th>
              <td mat-cell *matCellDef="let produto">
                <div>{{ produto.codigoProdutoAgrupado }}</div>
              </td>
            </ng-container>

            <ng-container matColumnDef="editar">
              <th mat-header-cell *matHeaderCellDef>
                  {{ "Editar" | translate }}
              </th>
              <td mat-cell *matCellDef="let produto">
                <button mat-icon-button matTooltip="{{ 'Editar' | translate }}" (click)="abrirForm(produto)">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </ng-container>
          </table>

          <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

