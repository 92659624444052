import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProdutoApiService } from 'src/app/industria/services/produto-api.service';
import { ProdutoFormComponent } from '../produto-form/produto-form.component';

@Component({
  selector: 'soi-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})
export class ProdutosComponent implements OnInit {
  produtos: Array<any>;

  industriaSelecionada: any;

  colunasTabelaProdutos: string[] = ['descricao', 'codigoProdutoIndustria', 'codigoProdutoAgrupado', 'editar'];
  dataSourceTabelaProdutos: MatTableDataSource<any>;
  tabelaProdutosRenderizada: boolean = false;
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private produtoService: ProdutoApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  construirTabelaProdutos(dataSource: any) {
    dataSource.sort((a, b) => a.descricao > b.descricao ? 1 : -1);
    this.dataSourceTabelaProdutos = new MatTableDataSource(dataSource);
    this.dataSourceTabelaProdutos.paginator = this.paginador;
    this.dataSourceTabelaProdutos.paginator.pageIndex = 0;
    this.tabelaProdutosRenderizada = this.dataSourceTabelaProdutos.data.length > 0 ?? false;
    this.dataSourceTabelaProdutos.sort = this.sort;
  }

  filtrarProdutos(filtro: string) {
    this.dataSourceTabelaProdutos.filter = filtro.toLowerCase();
  }

  getProdutosPorIndustria(industria) {
    this.industriaSelecionada = industria;

    this.produtoService.getProdutosPorIndustria(industria.id)
      .subscribe(
        response => {
          this.produtos = response.dados;
          this.construirTabelaProdutos(this.produtos);
        },
        erro => {

        });
  }

  abrirForm(produto: any = null) {

    let linguagens: any;

    this.produtoService.getLinguagensPorProdutoId(produto.idProduto)
      .subscribe(
        response => {
          linguagens = response.dados;
          produto['linguagens'] = linguagens;

          const dialogRef = this.dialog.open(
            ProdutoFormComponent,
            { data: produto }
          );

          dialogRef.componentInstance.industriaSelecionada = this.industriaSelecionada;

          dialogRef.afterClosed()
            .subscribe(
              novoProduto => {
                if (novoProduto) {
                  this.atualizarListaDeProdutos(novoProduto);
                  this.construirTabelaProdutos(this.produtos)
                }
              }
            );
        },
        erro => {

        });

  }

  atualizarListaDeProdutos(produto: any) {
    if (produto) {
      let indice = this.produtos?.findIndex(s => s.idProduto == produto.idProduto);

      if (indice > -1) {
        this.produtos[indice] = produto;
      }
      else {
        if (!this.produtos) {
          this.produtos = new Array<any>();
        }

        this.produtos.push(produto);
      }

      //this.ordenarPorDescricao();
    }
  }
}
