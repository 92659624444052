<div mat-dialog-title>
  {{ "Usuário da Indústria" | translate }}
</div>
<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="enviar()">
      <mat-form-field>
          <mat-label>{{"Usuário" | translate}}</mat-label>
          <input matInput formControlName="usuario" required />
          <mat-error>
              <div *ngIf="form.controls['usuario'].hasError('required')">
                  {{ "Campo obrigatório" | translate }}
              </div>
              <div *ngIf="form.controls['usuario'].hasError('duplicado')">
                  {{ "Esse usuário já existe" | translate }}
              </div>
          </mat-error>
      </mat-form-field>
      <mat-form-field>
          <mat-label>{{"Senha" | translate}}</mat-label>
          <input matInput [type]="mostrarSenha ? 'text' : 'password'" formControlName="senha" required />
          <button type="button" mat-icon-button matSuffix (click)="mostrarSenha = !mostrarSenha">
              <mat-icon>{{ mostrarSenha ? "visibility" : "visibility_off" }}</mat-icon>
          </button>
          <mat-error *ngIf="form.controls['senha'].hasError('required')">
              {{ "Campo obrigatório" | translate }}
          </mat-error>
      </mat-form-field>

        <mat-form-field>
          <mat-label>{{"E-mail Usuário" | translate}}</mat-label>
          <input matInput formControlName="email" required />
          <mat-error>
              <div *ngIf="form.controls['email'].hasError('required')">
                  {{ "Campo obrigatório" | translate }}
              </div>
              <div *ngIf="form.controls['email'].hasError('duplicado')">
                  {{ "Esse usuário já existe" | translate }}
              </div>
          </mat-error>
      </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "Distribuidoras" | translate }}</mat-label>
          <mat-select formControlName="distribuidoras">
              <mat-option *ngFor="let distribuidora of distribuidoras" [value]="distribuidora">
                  {{ distribuidora.nomeCompleto.toUpperCase() }}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['distribuidoras'].hasError('required')">
              {{ "Campo obrigatório" | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "Perfil Usuario" | translate }}</mat-label>
          <mat-select formControlName="perfil_usuario">
              <mat-option *ngFor="let perfil of perfil_usuario" [value]="perfil">
                  {{ perfil.descricao.toUpperCase() }}
              </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['perfil_usuario'].hasError('required')">
              {{ "Campo obrigatório" | translate }}
          </mat-error>
        </mat-form-field>

  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
  </button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="enviar()">
      <mat-icon>done</mat-icon>
  </button>
</div>
