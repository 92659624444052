<div class="patch-input-container" [style.width]="width" [formGroup]="patchInputForm">
    <div class="input-area">
        <input [value]="value" [type]="type" [style.textAlign]="textAlign" class="input-edicao"
            (keyup)="habilitarAcoes()" formControlName="patchInputValue">

        <div *ngIf="valueAlterado && inputValue.invalid && (inputValue.dirty || inputValue.touched)"
            class="form-field-error">
            {{ mensagemErro }}
        </div>
    </div>

    <div class="acoes">
        <button mat-icon-button disableRipple [disabled]="!valueAlterado || inputValue.invalid" class="acao-btn"
            (click)="salvar()" matTooltip="{{ 'Salvar' | translate }}">
            <mat-icon>save</mat-icon>
        </button>
        <button mat-icon-button disableRipple [disabled]="!valueAlterado" class="acao-btn" (click)="desfazer()"
            matTooltip="{{ 'Desfazer' | translate }}">
            <mat-icon>undo</mat-icon>
        </button>
    </div>
</div>