import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/comum/services/base-api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PaisApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getPaisesIndustria(idIndustria: number) {
    return this.get(`Pais/GetByIndustria/${idIndustria}`);
  }

  getAll() {
    return this.get(`Pais`);
  }
}
