<div class="container-fluid">
  <div class="row mt-3 mb-1">
    <div class="col-12 seletor-flex">
      <soi-seletor-industria-distribuidora [mostrarOpcaoTodas]="true"
        (industriaSelecionada)="setIndustriaSelecionada($event)"
        (distribuidoraSelecionada)="setDistribuidoraSelecionada($event)"></soi-seletor-industria-distribuidora>

      <soi-seletor-mes-ano (periodoSelecionado)="setPeriodoSelecionado($event)" [permitirTodoPeriodo]="true">
      </soi-seletor-mes-ano>

      <div class="acoes">
        <mat-button-toggle-group *ngIf="listaFiltrada" [(value)]="filtroStatus" #group="matButtonToggleGroup"
          class="mr-2">
          <mat-button-toggle *ngFor="let item of statusOptions" [value]="item" (change)="selectionChanged($event)">
            {{ item.descricao | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        <div class="spacer"></div>

        <button mat-raised-button (click)="getMovimentacao()" color="primary">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row mt-3 mb-1">
    <div class="col-12 card-periodo-list-container">
      <mat-card *ngFor="let periodo of listaFiltrada" class="card-periodo">
        <mat-card-header>
          <mat-card-title>{{ getValorPeriodo(periodo.periodo) | titlecase }}</mat-card-title>
          <mat-card-subtitle> {{ periodo.industria }} </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="card-periodo-conteudo">
          <mat-list>
            <mat-list-item *ngFor="let movimentacao of periodo.movimentacoes">
              <mat-icon mat-list-icon *ngIf="movimentacao.finalizado" class="status-movimentacao-fechado">done
              </mat-icon>
              <mat-icon mat-list-icon *ngIf="!movimentacao.finalizado" class="status-movimentacao-aberto">close
              </mat-icon>
              <div mat-line> {{ movimentacao.paisDistribuidora }} - {{ movimentacao.nomeDistribuidora }} </div>
              <div mat-line> {{ (movimentacao.finalizado ? "Finalizado" : "Em progresso") | translate }} </div>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>