import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'soi-seletor-industria-distribuidora',
  templateUrl: './seletor-industria-distribuidora.component.html',
  styleUrls: ['./seletor-industria-distribuidora.component.css']
})
export class SeletorIndustriaDistribuidoraComponent implements OnInit {
  industrias: Array<any>;
  industria: any;
  distribuidora: any;
  @Output() industriaSelecionada = new EventEmitter<any>();
  @Output() distribuidoraSelecionada = new EventEmitter<any>();
  @Input() mostrarOpcaoTodas = false;

  constructor() { }

  ngOnInit(): void {
    this.industrias = JSON.parse(localStorage.getItem('industriasUsuario'));
    if (this.industrias == null) {
      this.industrias = [];
    }
    this.setValorSeletores();
  }

  setValorSeletores() {
    if (this.mostrarOpcaoTodas) {
      this.distribuidora = 0;
    }

    if (this.industrias.length == 1) {
      this.industria = this.industrias[0];

      this.emitirIndustria();

      if (this.industria.distribuidoras.length == 1) {
        this.distribuidora = this.industria.distribuidoras[0];

        this.emitirDistribuidora();
      }
    }
  }

  emitirIndustria() {
    this.industriaSelecionada.emit(this.industria);
  }

  emitirDistribuidora() {
    this.distribuidoraSelecionada.emit(this.distribuidora);
  }
}
