export enum PerfilUsuario {
    Administrador = 1,
    Industria,
    Distribuidora
}

export enum Rotas {
    Login = 'login',
    Logout = 'logout',
    TokenEcommerce = 'token-ecomm',
    PageError = 'page-error',
    NotFound = 'not-found',
    CadastroProdutos = 'industry/products',
    Distribuidoras = 'industry/distributor',
    CadastroUsuarios = 'industry/users',
    Movimentacoes = 'industry/transactions',
    StatusMovimentacoes = 'industry/status',
    AssociacaoMixProdutos = 'industry/configurations/product-mix-association',
    PainelDistribuidor = 'distributor/sales-report',
    EmailsAviso = 'industry/configurations/warning-emails',
    DashboardDistribuidor = 'distributor/dashboard'
}
