import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


declare const tinycolor: any; // tinycolor é uma lib js, este objeto é só para não dar problema ao compilar

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizacaoService {
  cliente: string;

  // TODO corrigir cores
  cores = {
    'Kin': {
      primaria : '#3F51B5',
      secundaria: '#9E9E9E'
    },
    'Target': {
      primaria : '#ff5722',
      secundaria: '#9E9E9E'
    }
  };

  primaryColorPalette: Color[] = [];
  secondaryColorPalette: Color[] = [];


  constructor() {
    this.cliente = environment.client ? environment.client : 'Target';
  }

  DeterminarCores() {
    let corPrimaria = '';
    let corSecundaria = '';

    switch(this.cliente) {
      case 'Kin':
        document.documentElement.style.setProperty('--matToolBarBackground', 'white');
        corPrimaria = this.cores.Kin.primaria;
        corSecundaria = this.cores.Kin.secundaria;
        break;
      default:
        document.documentElement.style.setProperty('--matToolBarBackground', '#424242');
        corPrimaria = this.cores.Target.primaria;
        corSecundaria = this.cores.Target.secundaria;
        break;
    }

    this.savePrimaryColor(corPrimaria);
    this.saveSecondaryColor(corSecundaria);
  }

  savePrimaryColor(cor: string) {
    this.primaryColorPalette = this.computeColors(cor);

    for (const color of this.primaryColorPalette) {
      const key = `--theme-primary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }

  saveSecondaryColor(cor: string) {
    this.secondaryColorPalette = this.computeColors(cor);

    for (const color of this.secondaryColorPalette) {
      const key = `--theme-secondary-${color.name}`;
      const value = color.hex;
      document.documentElement.style.setProperty(key, value);
    }
  }


  computeColors(hex: string): Color[] {
    return [
      this.getColorObject(tinycolor(hex).lighten(52), '50'),
      this.getColorObject(tinycolor(hex).lighten(37), '100'),
      this.getColorObject(tinycolor(hex).lighten(26), '200'),
      this.getColorObject(tinycolor(hex).lighten(12), '300'),
      this.getColorObject(tinycolor(hex).lighten(6), '400'),
      this.getColorObject(tinycolor(hex), '500'),
      this.getColorObject(tinycolor(hex).darken(6), '600'),
      this.getColorObject(tinycolor(hex).darken(12), '700'),
      this.getColorObject(tinycolor(hex).darken(18), '800'),
      this.getColorObject(tinycolor(hex).darken(24), '900'),
      this.getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
    ];
  }

  getColorObject(value, name): Color {
    const c = tinycolor(value);

    return {
      name: name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }

}
