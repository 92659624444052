<div mat-dialog-title>
  <div>
      {{ "Distribuidor" | translate }}
  </div>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="enviar()">
    <mat-form-field>
      <mat-label>{{"Nome" | translate}}</mat-label>
      <input matInput formControlName="nomeCompleto" required />

      <mat-error *ngIf="form.controls['nomeCompleto'].hasError('required')">
          {{ "Campo obrigatório" | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{"Documento" | translate}}</mat-label>
      <input matInput formControlName="nroDocumento" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{"Código" | translate}}</mat-label>
      <input matInput formControlName="idSistemaExterno" required />

      <mat-error *ngIf="form.controls['idSistemaExterno'].hasError('required')">
          {{ "Campo obrigatório" | translate }}
      </mat-error>
    </mat-form-field>

    <soi-seletor-pais [paisInicial]="distribuidoraExistente?.pais"
    (componentePronto)="adicionarPaisControl($event)">
    </soi-seletor-pais>

    <mat-form-field>
      <mat-label>{{ "Indústrias" | translate }}</mat-label>
      <mat-select formControlName="industrias" [compareWith]="compararIndustrias">
          <mat-option *ngFor="let industria of industrias" [value]="industria"
              [disabled]="!!industria.opcaoDesabilitada">
              {{ industria.nomeCompleto.toUpperCase() }}
          </mat-option>
      </mat-select>

      <mat-hint>{{ "Atenção: não é possível desvincular uma indústria já vinculada" | translate }}</mat-hint>

      <mat-error *ngIf="form.controls['industrias'].hasError('required')">
          {{ "Campo obrigatório" | translate }}
      </mat-error>
  </mat-form-field>

  </form>
</div>

<div mat-dialog-actions>
  <button mat-raised-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
  </button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="enviar()">
      <mat-icon>done</mat-icon>
  </button>
</div>
