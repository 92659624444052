<div class="container-fluid">
  <div class="row mt-3 mb-1">
    <div class="col-12 seletor-flex">
      <soi-seletor-industria-distribuidora [mostrarOpcaoTodas]="true"
        (industriaSelecionada)="setIndustriaSelecionada($event)"
        (distribuidoraSelecionada)="setDistribuidoraSelecionada($event)"></soi-seletor-industria-distribuidora>

      <soi-seletor-mes-ano (periodoSelecionado)="setPeriodoSelecionado($event)">
      </soi-seletor-mes-ano>

      <div class="acoes">
        <button *ngIf="tabelaMovimentacaoRenderizada" mat-button color="primary" (click)="exportar()">
          {{ "Exportar" | translate }}
        </button>

        <div class="spacer"></div>

        <button *ngIf="permitirAlteracaoStatus" mat-raised-button color="primary" (click)="alterarStatusMovimentacao()">
          {{ "Alterar status" | translate }}
        </button>

        <div class="spacer"></div>
        <button mat-raised-button (click)="getMovimentacao()" color="primary">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <soi-mensagem-notificacao [mostrar]="!tabelaMovimentacaoRenderizada" [fechavel]="false" mensagem="{{
    'Aplique os filtros acima para visualizar as movimentações.' | translate
  }}">
  </soi-mensagem-notificacao>
  <div class="row">
    <div class="col-12">
      <div [hidden]="!tabelaMovimentacaoRenderizada" class="soi-table-container-sm">
        <mat-form-field class="w-100">
          <mat-label>{{ "Filtrar" | translate }}</mat-label>
          <input matInput (keyup)="filtrarTabela($event.target.value)"
            placeholder="{{ 'Digite o código, descrição ou quantidade' | translate }}" />
        </mat-form-field>

        <div class="soi-table mat-elevation-z8">
          <div class="status-movimentacao" *ngIf="statusMovimentacao != ''"
            [class.status-movimentacao-aberto]="statusMovimentacao == 'Em progresso'"
            [class.status-movimentacao-fechado]="statusMovimentacao == 'Finalizado'">
            {{ statusMovimentacao | translate }}
          </div>
          <table mat-table [dataSource]="dataSourceTabelaMovimentacao" matSort>
            <ng-container matColumnDef="codigoProdutoAgrupado">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "Código do Grupo" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.codigoProdutoAgrupado }}
              </td>

              <td mat-footer-cell *matFooterCellDef class="tdTotal"> Total </td>

            </ng-container>

            <ng-container matColumnDef="codigoProdutoIndustria">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "Código" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.codigoProdutoIndustria }}
              </td>

              <td mat-footer-cell *matFooterCellDef></td>

            </ng-container>

            <ng-container matColumnDef="descricao">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "Descrição" | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                <div [ngSwitch]="this.lingua">
                  <div *ngSwitchCase="'en'">{{ element.descricaoIngles }}</div>
                  <div *ngSwitchCase="'es'">{{ element.descricaoEspanhol }}</div>
                  <div *ngSwitchCase="'fr'">{{ element.descricaoFrances }}</div>
                  <div *ngSwitchDefault>{{ element.descricao }}</div>
                </div>
              </td>

              <td mat-footer-cell *matFooterCellDef></td>

            </ng-container>

            <ng-container matColumnDef="quantidade">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ "Quantidade" | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="soi-table-cell-right">{{ element.quantidade }}</td>

              <td mat-footer-cell *matFooterCellDef class="soi-table-cell-right tdTotal">{{totalQuantidade}}</td>

            </ng-container>

            <span *ngIf="env == 'Kin'">
              <!-- colunas somente KIN -->
              <ng-container matColumnDef="estoque">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ "Estoque" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="soi-table-cell-right">{{ element.estoque }}</td>

                <td mat-footer-cell *matFooterCellDef class="soi-table-cell-right tdTotal">{{totalEstoque}}</td>

              </ng-container>

              <ng-container matColumnDef="valor">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ "Valor" | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="soi-table-cell-right">{{ element.valor }}</td>

                <td mat-footer-cell *matFooterCellDef class="soi-table-cell-right tdTotal">{{totalValor}}</td>

              </ng-container>
            </span>

            <tr mat-header-row *matHeaderRowDef="colunasTabelaMovimentacao"></tr>
            <tr mat-row *matRowDef="let row; columns: colunasTabelaMovimentacao"></tr>

            <tr mat-footer-row *matFooterRowDef="colunasTabelaMovimentacao"></tr>

          </table>

          <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>