import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadArquivoFeedbackService {
  private mensagensFeedbackSource = new Subject<Array<object>>();
  mensagensFeedback$ = this.mensagensFeedbackSource.asObservable();

  adicionarMensagens(mensagens: Array<object>) {
    this.mensagensFeedbackSource.next(mensagens);
  }
}
