import { Component, Input } from '@angular/core';
import { ImportarArquivoService, ParametrosImportacaoArquivo } from '../../services/importar-arquivo.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UploadArquivoFeedbackService } from '../../services/upload-arquivo-feedback.service';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';

@Component({
  selector: 'soi-upload-arquivo',
  templateUrl: './upload-arquivo.component.html',
  styleUrls: ['./upload-arquivo.component.css']
})
export class UploadArquivoComponent {
  arquivoAtual: File;

  @Input()
  parametros: ParametrosImportacaoArquivo;

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private importarArquivoService: ImportarArquivoService,
    private uploadArquivoFeedbackService: UploadArquivoFeedbackService
  ) { }

  selecionarArquivo(arquivo: FileList) {
    if (arquivo.length === 0) {
      return;
    }

    this.arquivoAtual = arquivo[0];
  }

  importarArquivo() {
    if (!this.arquivoAtual) {
      this.snackBar.open(this.translate.instant('Selecione um arquivo'), '', { duration: 2000 });
      return;
    }

    if (!this.parametros.idIndustria || !this.parametros.idDistribuidora) {
      this.snackBar.open(this.translate.instant('Selecione a indústria e a distribuidora'), '', { duration: 2000 });

      return;
    }

    this.parametros.arquivo = this.arquivoAtual;

    this.importarArquivoService.importarArquivo(this.parametros)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }
          let resultado = resposta.dados;

          if (resultado.mensagem) {
            let mensagemJson = JSON.parse(resultado.mensagem);

            let dadosMensagens = [];
            for (let i in mensagemJson) {
              dadosMensagens[i] = {
                tipoMensagem: "mensagem_padronizada." + mensagemJson[i].tipoMensagem,
                valor1: mensagemJson[i].parametros?.[0] ?? "",
                valor2: mensagemJson[i].parametros?.[1] ?? "",
                valor3: mensagemJson[i].parametros?.[2] ?? ""
              }
            }
            this.uploadArquivoFeedbackService.adicionarMensagens(dadosMensagens);
            this.arquivoAtual = undefined;
          }
        },
        erro => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'), '', { duration: 2000 });
          this.arquivoAtual = undefined;
        }
      )
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }
}
