import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  private httpOptions;
  protected baseUrl: string;

  constructor(
    protected httpClient: HttpClient
  ) {
    this.baseUrl = environment.endpointAPI;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }

  construirUrl(rota: string): string {
    if (rota.charAt(0) != '/') {
      rota = '/' + rota
    }

    return this.baseUrl + rota;
  }

  get(rota: string): Observable<any> {
    const url = this.construirUrl(rota);

    return this.httpClient.get<any>(url, this.httpOptions)
      .pipe(
        catchError(this.tratarErro)
      );
  }

  getFullReponse(rota: string): Observable<HttpResponse<any>> {
    const url = this.construirUrl(rota);

    return this.httpClient.get<any>(url, { observe: 'response' })
      .pipe(
        catchError(this.tratarErro)
      );
  }

  post(dados: any, rota: string): Observable<any> {
    const url = this.construirUrl(rota);

    return this.httpClient.post(url, dados, this.httpOptions)
      .pipe(
        catchError(this.tratarErro)
      );
  }

  update(dados: any, rota: string): Observable<any> {
    const url = this.construirUrl(rota);

    return this.httpClient.put(url, dados, this.httpOptions)
      .pipe(
        catchError(this.tratarErro)
      );
  }

  patch(rota: string, dados?: any): Observable<any> {
    const url = this.construirUrl(rota);

    return this.httpClient.patch(url, dados, this.httpOptions)
      .pipe(
        catchError(this.tratarErro)
      );
  }

  delete(rota): Observable<any> {
    const url = this.construirUrl(rota);

    return this.httpClient.delete(url, this.httpOptions)
      .pipe(
        catchError(this.tratarErro)
      );
  }

  tratarErro(erro) {
    let errorMessage = '';

    if (erro.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = erro.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${erro.status}\nMessage: ${erro.message}`;
    }
    console.debug(errorMessage);
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
