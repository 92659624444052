import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'soi-mensagem-notificacao',
  templateUrl: './mensagem-notificacao.component.html',
  styleUrls: ['./mensagem-notificacao.component.css']
})
export class MensagemNotificacaoComponent {

  @Input()
  mostrar: boolean;

  @Input()
  mensagem: string;

  @Input()
  fechavel: boolean;

  constructor() {
    this.mostrar = false;
    this.mensagem = '';
    this.fechavel = true;
  }

  fecharNotificacao() {
    this.mostrar = false;
  }
}
