<div class="container-fluid">
  <div class="row mt-3 mb-1">
      <div class="col">
          <soi-seletor-industria (industriaSelecionada)="getDistribuidorasPorIndustria($event)">
          </soi-seletor-industria>
      </div>

      <div class="col acoes">
          <div>
              <button mat-raised-button color="primary" (click)="abrirForm()">
                  {{ "Novo distribuidor" | translate }}
              </button>
          </div>
      </div>
  </div>

  <soi-mensagem-notificacao [mostrar]="!industriaSelecionada" [fechavel]="false" mensagem="{{
      'Selecione uma indústria.' | translate
    }}">
  </soi-mensagem-notificacao>

  <soi-mensagem-notificacao [mostrar]="distribuidoras?.length == 0" [fechavel]="false" mensagem="{{
      'Não existem distribuidores associados à indústria selecionada.' | translate
    }}">
  </soi-mensagem-notificacao>

  <div class="lista-cadastro">
      <div *ngFor="let distribuidora of distribuidoras" class="lista-cadastro-item flex-column">
          <div class="d-flex align-items-center">
              <div class="lista-cadastro-item-conteudo">
                  <div class="lista-cadastro-item-informacao-linha">
                      <div class="lista-cadastro-item-informacao distribuidora-nome">
                          <b>{{ distribuidora.nomeCompleto }}</b>
                      </div>

                      <div class="row">
                          <div class="col-sm-3 col-md-2">
                              {{ distribuidora.idSistemaExterno }}
                          </div>

                          <div class="col-sm lista-cadastro-item-informacao">
                              {{ distribuidora.pais.nome | translate }} ({{ distribuidora.pais.sigla }})
                          </div>
                      </div>
                  </div>
              </div>

              <div class="lista-cadastro-item-acoes">
                  <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="{{ 'Mais' | translate }}">
                      <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="abrirForm(distribuidora)">
                          {{ "Editar" | translate }}
                      </button>
                  </mat-menu>
              </div>
          </div>


      </div>
  </div>
</div>
