import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { EmailAvisoApiService } from 'src/app/industria/services/email-aviso-api.service';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'soi-envio-emails-aviso-form',
  templateUrl: './envio-emails-aviso-form.component.html',
  styleUrls: ['./envio-emails-aviso-form.component.css']
})
export class EnvioEmailsAvisoFormComponent {

  get email() {
    return this.emailAvisoForm.get('email');
  }

  get diaMesEnvio() {
    return this.emailAvisoForm.get('diaMesEnvio');
  }

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    private envioEmailApiService: EmailAvisoApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  emailAvisoForm = this.formBuilder.group({
    idIndustriaDistribuidora: [null, Validators.required],
    email: [null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]], // TODO revisar patterns
    diaMesEnvio: [null, [Validators.required, Validators.min(1), Validators.max(31), Validators.pattern('^[0-9]*$')]] // TODO revisar patterns
  });

  setIdIndustriaDistribuidora(distribuidora) {
    this.emailAvisoForm.controls['idIndustriaDistribuidora'].setValue(distribuidora.idIndustriaDistribuidora);
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  enviar() {
    this.envioEmailApiService.inserirEmailAviso(this.emailAvisoForm.value)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }

          this.dialogRef.close(resposta.dados);
        }
      )
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }
}
