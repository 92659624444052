import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CustomizacaoService } from 'src/app/comum/services/customizacao.service';
import * as moment from 'moment';

@Component({
  selector: 'soi-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Target-SellOutIndustria-FrontEnd';
  linguaSessao: string;

  linguasValidas = [
    'en',
    'es',
    'fr',
    'pt'
  ];
  constructor(
    private translate: TranslateService,
    private customizacao: CustomizacaoService
  ) {
    this.inicializaTradutor();
    customizacao.DeterminarCores();
  }

  /**
   * Checa a existência da configuração de língua na sessão.
   * Usa a do navegador por padrão.
   */
  inicializaTradutor(): void {
    // deve usar a lingua do navegador por padrão
    this.linguaSessao = this.translate.getBrowserLang();

    // caso não exista tradução para esta lingua, usa inglês
    if (this.linguasValidas.indexOf(this.linguaSessao) === -1) {
      this.linguaSessao = this.linguasValidas[0];
    }
    localStorage.setItem('lingua', this.linguaSessao);

    this.translate.use(this.linguaSessao);
    moment.locale(this.linguaSessao);
  }
}
