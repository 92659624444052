import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/comum/services/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class DistribuidoraApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  inserir(distribuidora: any) {
    return this.post(distribuidora, 'Distribuidora/Add');
  }
  atualizar(distribuidora: any) {
    return this.update(distribuidora, `Distribuidora/Alter/${distribuidora.idDistribuidora}`);
  }

  getDistribuidorasIndustria(id: number) {
    return this.get(`Distribuidora/GetByIndustria/${id}`);
  }

  getAll() {
    return this.get('Distribuidora/');
  }
}
