<div class="container-fluid">

    <div class="row mt-3 mb-1">
        <div class="col-12 seletor-flex">

            <div class="col-12 seletor-flex">
                <soi-seletor-industria-distribuidora class="d-flex align-items-center flex-wrap"
                    (industriaSelecionada)="setIndustriaSelecionada($event)"
                    (distribuidoraSelecionada)="setDistribuidoraSelecionada($event)">
                </soi-seletor-industria-distribuidora>
    
                <!-- <div class="seletor-flex">
                    <mat-form-field appearance="outline" class="filtro-periodo">
                      <mat-label>{{"Ano" | translate}}</mat-label>
                  
                        <mat-select [(ngModel)]="selectedYear" name="year" >
                          <mat-option value="2019">
                            2019
                          </mat-option>
                          <mat-option value="2020">
                            2020
                          </mat-option>
                        </mat-select>
                      
                    </mat-form-field>

                  </div> -->
                  
    
                <div class="acoes d-flex align-items-center" >
                    <button mat-raised-button matTooltip="{{ 'Buscar' | translate }}"
                    (click)="buscarUrlAcessoDistribuidora()" color="primary" [disabled] = "false">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>

           <!-- GRAFICO -->
           <div class="col-lg-12 col-md-12" *ngIf = "mostrarGrafico" >
                <iframe width="100%" height="750"
                    [src]="linkPowerBiDist"
                    frameborder="0" allowFullScreen="true">
                </iframe>
            </div>


        </div>
    </div>
</div>

