import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DistribuidoraApiService } from 'src/app/industria/services/distribuidora-api.service';
import { PaisApiService } from 'src/app/industria/services/pais-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';

@Component({
  selector: 'soi-seletor-industria-pais-distribuidora',
  templateUrl: './seletor-industria-pais-distribuidora.component.html',
  styleUrls: ['./seletor-industria-pais-distribuidora.component.css']
})
export class SeletorIndustriaPaisDistribuidoraComponent implements OnInit {
  industrias: Array<any>;
  industria: any;
  pais: any;
  distribuidora: any;
  paisesDaIndustria: any = [];
  distribuidorasDaIndustria: any;
  distribuidorasPorPais: any;
  @Output() industriaSelecionada = new EventEmitter<any>();
  @Output() distribuidoraSelecionada = new EventEmitter<any>();

  constructor(
    private paisApiService: PaisApiService,
    private distribuidoraApiService: DistribuidoraApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => { this.mudouLingua() });
  }

  ngOnInit(): void {
    this.industrias = JSON.parse(localStorage.getItem('industriasUsuario'));
    if (this.industrias == null) {
      this.industrias = [];
    }

    this.setValorSeletores();
    this.carregarSeletores();
  }

  setValorSeletores() {
    if (this.industrias.length == 1) {
      this.industria = this.industrias[0];

      this.emitirIndustria();
    }
  }

  filtrarDistribuidorasPorPais(paisSelecionado: any) {
    this.distribuidorasPorPais = this.distribuidorasDaIndustria.filter(distribuidora => distribuidora.pais.idPais == paisSelecionado.idPais);
  }

  carregarSeletores() {
    if (this.industria && this.industria.id) {
      this.paisApiService.getPaisesIndustria(this.industria.id)
        .subscribe(resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }

          let paises = resposta.dados;
          if (paises == null) {
            this.paisesDaIndustria = [];
          } else {
            this.paisesDaIndustria = paises;
            this.mudouLingua();
          }
        });

      this.distribuidoraApiService.getDistribuidorasIndustria(this.industria.id)
        .subscribe(resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }
          let distribuidoras = resposta.dados;
          this.distribuidorasDaIndustria = distribuidoras.sort((a, b) => a.nomeCompleto > b.nomeCompleto ? 1 : -1);
        });
    }
  }

  mudouLingua() {
    this.paisesDaIndustria.sort((a, b) => this.translate.instant(a.nome) > this.translate.instant(b.nome) ? 1 : -1);
  }

  emitirIndustria() {
    this.industriaSelecionada.emit(this.industria);
    this.distribuidorasPorPais = [];
    this.carregarSeletores();
  }

  emitirDistribuidora() {
    this.distribuidoraSelecionada.emit(this.distribuidora);
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }

}