import { NgModule } from '@angular/core';
import { ComumModule } from '../comum/comum.module';

// Componentes do módulo
import { DadosMovimentacoesComponent } from './components/consultas/dados-movimentacoes/dados-movimentacoes.component';
import { StatusMovimentacoesComponent } from './components/consultas/status-movimentacoes/status-movimentacoes.component';
import { ConfiguracoesComponent } from './components/configuracoes/configuracoes.component';
import { EnvioEmailsAvisoFormComponent } from './components/configuracoes/envio-emails-aviso-form/envio-emails-aviso-form.component';
import { AssociacaoMixProdutosComponent } from './components/configuracoes/associacao-mix-produtos/associacao-mix-produtos.component';
import { EnvioEmailsAvisoListaComponent } from './components/configuracoes/envio-emails-aviso-lista/envio-emails-aviso-lista.component';
import { ProdutosComponent } from './components/cadastros/produtos/produtos.component';
import { ProdutoFormComponent } from './components/cadastros/produto-form/produto-form.component';
import { DistribuidorasComponent } from './components/cadastros/distribuidoras/distribuidoras.component';
import { DistribuidoraFormComponent } from './components/cadastros/distribuidora-form/distribuidora-form.component';
import { DistribuidoraEmpresaFormComponent } from './components/cadastros/distribuidora-empresa-form/distribuidora-empresa-form.component';
import { UsuariosIndustriaComponent } from './components/cadastros/usuario-industria/usuario-industria.component';
import { UsuarioIndustriaFormComponent } from './components/cadastros/usuario-industria-form/usuario-industria-form.component';
import { UsuarioIndustriaAtualizacaoFormComponent } from './components/cadastros/usuario-industria-atualizacao-form/usuario-industria-atualizacao-form.component';


@NgModule({
  declarations: [
    DadosMovimentacoesComponent,
    StatusMovimentacoesComponent,
    ConfiguracoesComponent,
    EnvioEmailsAvisoFormComponent,
    AssociacaoMixProdutosComponent,
    EnvioEmailsAvisoListaComponent,
    ProdutosComponent,
    ProdutoFormComponent,
    DistribuidorasComponent,
    DistribuidoraFormComponent,
    DistribuidoraEmpresaFormComponent,
    UsuariosIndustriaComponent,
    UsuarioIndustriaFormComponent,
    UsuarioIndustriaAtualizacaoFormComponent
  ],
  imports: [
    ComumModule
  ],
  exports: [
    DadosMovimentacoesComponent,
    StatusMovimentacoesComponent,
    ConfiguracoesComponent,
    EnvioEmailsAvisoFormComponent,
    AssociacaoMixProdutosComponent,
    ProdutosComponent,
    ProdutoFormComponent
  ]
})
export class IndustriaModule { }
