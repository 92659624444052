import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from "@ngx-translate/core";

// rippado de https://stackoverflow.com/a/49532776
export class TraducaoPaginadorService extends MatPaginatorIntl {
  translate: TranslateService;
  itemsPerPageLabel = 'Itens por página:';
  nextPageLabel = 'Próxima página';
  previousPageLabel = 'Página anterior';
  firstPageLabel = 'Primeira página';
  lastPageLabel = 'Última página';

  getRangeLabel = function (page, pageSize, length) {
    const of = this.translate ? this.translate.instant('de') : 'of';
    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;

    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = this.translate.instant('Itens por página:');
    this.nextPageLabel = this.translate.instant('Próxima página');
    this.previousPageLabel = this.translate.instant('Página anterior');
    this.firstPageLabel = this.translate.instant('Primeira página');
    this.lastPageLabel = this.translate.instant('Última página');

    this.changes.next()
  }

}