import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'soi-seletor-mes-ano',
  templateUrl: './seletor-mes-ano.component.html',
  styleUrls: ['./seletor-mes-ano.component.css']
})
export class SeletorMesAnoComponent implements OnInit {
  private _mesAno: FormControl;

  set mesAno(value: any) {
    if (value) { this._mesAno = new FormControl(moment(value)); }
  }

  get mesAno(): any {
    return this._mesAno;
  }
  todoPeriodo: FormControl;

  minDate: Date;

  maxDate: Date;

  @Input() permitirTodoPeriodo: boolean;
  exibirMesAno: boolean = true;

  @Output()
  periodoSelecionado: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dateAdapter: DateAdapter<any>, private translate: TranslateService) { }

  ngOnInit(): void {
    this.dateAdapter.setLocale(this.translate.getBrowserLang());
    this.minDate = new Date(2011, 0, 1);
    this.maxDate = new Date();
    this.mesAno = new FormControl(moment());
    this.todoPeriodo = new FormControl();

    this.getPeriodoSelecionado();
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.mesAno.value;
    ctrlValue.year(normalizedYear.year());
    this.mesAno.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.mesAno.value;
    ctrlValue.month(normalizedMonth.month());
    this.mesAno.setValue(ctrlValue);
    datepicker.close();

    this.getPeriodoSelecionado();
  }

  getPeriodoSelecionado() {
    let periodoEmitir: string;
    if (this.mesAno.disabled) {
      periodoEmitir = '0001-01-01';
    } else {
      periodoEmitir = this.mesAno.value.format('YYYY-MM-01');
    }
    this.periodoSelecionado.emit(periodoEmitir);
  }

  definirPeriodoTodo() {
    if (this.permitirTodoPeriodo && this.todoPeriodo.value) {
      this.exibirMesAno = false;
      this.mesAno.disable();
    } else {
      this.exibirMesAno = true;
      this.mesAno.enable();
    }
    this.getPeriodoSelecionado();
  }
}
