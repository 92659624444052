import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from 'src/app/comum/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from 'src/app/comum/services/session.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  error = '';

  constructor(
    private router: Router,
    private loginService: LoginService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private session: SessionService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let telaAtual = route.routeConfig['path'];

    // primeiro, checa se está na tela de token
    if (telaAtual === 'token-ecomm') {
      const retorno = this.trataTokenPainel(route, state);

      return retorno;
    }

    // ve se está logado
    let tokenWebApi = localStorage.getItem('tokenWebApi');
    let dadosUsuario = this.session.getItem('dadosUsuario');

    if (dadosUsuario != null && tokenWebApi) {
      // e confere se tem permissão para esta tela
      dadosUsuario = JSON.parse(dadosUsuario);
      let telas = dadosUsuario['perfil']['telas'];
      let permitido = false;

      for (let i in telas) {
        if (telas[i] == telaAtual) {
          permitido = true;
        }
      }

      if (!permitido) {
        this.snackBar.open(this.translate.instant('Você não tem permissão para esta ação!'), '', { duration: 2000 });
      }

      return permitido;
    }

    this.snackBar.open(this.translate.instant('Favor realizar login'), '', { duration: 2000 });
    this.router.navigate(['/login']);

    return false;
  }

  async trataTokenPainel(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // return true;
    await this.loginService.loginComTokenPainel(state.url)
      .then(success => {
        const tela = this.session.getItem('tela');
        const tokenWebApi = this.session.getItem('tokenWebApi');
        if (!tokenWebApi) {
          this.router.navigate(['/pageerror']);
          return true;
        } else {
          this.router.navigate([tela]);
          return false;
        }
      },
        error => {
          this.error = error;
          this.router.navigate(['/pageerror']);

          return false;
        });

    return false;
  }
}
