<div class="container-fluid">
    <div class="row mt-3 mb-1">

        <div class="col-12 seletor-flex">
            <soi-seletor-industria-pais-distribuidora (industriaSelecionada)="setIndustriaSelecionada($event)"
                (distribuidoraSelecionada)="buscarMixProdutos($event)">
            </soi-seletor-industria-pais-distribuidora>
            <div *ngIf="tabelaAssociacaoRenderizada" class="acoes">
                <button mat-raised-button color="primary"
                    (click)="salvarMixDeProdutos()">{{ "Salvar" | translate }}</button>
            </div>
        </div>

    </div>

    <soi-mensagem-notificacao [mostrar]="!tabelaAssociacaoRenderizada" [fechavel]="false"
        mensagem="{{ 'Selecione um país e uma distribuidora para associar os produtos.' | translate }}">
    </soi-mensagem-notificacao>

    <div class="row">
        <div class="col">
            <!-- utilizando hidden, pois o *ngIf quebra o paginador -->
            <div [hidden]="!tabelaAssociacaoRenderizada" class="soi-table-container-sm">
                <mat-form-field class="w-100 my-2">
                    <mat-label>{{ "Filtrar" | translate }}</mat-label>
                    <input matInput (keyup)="filtrarProdutosDoMix($event.target.value)"
                        placeholder="{{ 'Digite o código, descrição ou código do grupo' | translate }}">
                </mat-form-field>

                <div class="soi-table mat-elevation-z8">
                    <table mat-table [dataSource]="dataSourceTabelaAssociacao" matSort>
                        <tr mat-header-row *matHeaderRowDef="colunasTabelaAssociacao"></tr>
                        <tr mat-row *matRowDef="let row; columns: colunasTabelaAssociacao;"></tr>

                        <ng-container matColumnDef="descricao">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "Descrição" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let produtoMix">
                                <div [ngSwitch]="this.lingua">
                                  <div *ngSwitchCase="'en'">{{ produtoMix.descricaoIngles }}</div>
                                  <div *ngSwitchCase="'es'">{{ produtoMix.descricaoEspanhol }}</div>
                                  <div *ngSwitchCase="'fr'">{{ produtoMix.descricaoFrances }}</div>
                                  <div *ngSwitchDefault>{{ produtoMix.descricao }}</div>
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="codigoProdutoIndustria">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "Código" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let produtoMix">
                                {{ produtoMix.codigoProdutoIndustria }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="codigoProdutoAgrupado">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "Código do Grupo" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let produtoMix">
                                {{ produtoMix.codigoProdutoAgrupado }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="associado">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ "Associado" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let produtoMix" class="soi-table-cell-center">
                                <mat-checkbox [(ngModel)]="produtoMix.associado"
                                    (change)="$event ? selectionParcial.toggle(produtoMix) : produtoMix"
                                    [checked]="produtoMix.associado"></mat-checkbox>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="masterToggleParcial">
                            <th mat-header-cell *matHeaderCellDef>
                                <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selectionParcial.hasValue() && isAllSelected()"
                                    [indeterminate]="selectionParcial.hasValue() && !isAllSelected()"
                                    matTooltip="{{ 'Selecionar todos os produtos exibidos' | translate }}">
                                </mat-checkbox>
                            </th>
                            <td mat-cell *matCellDef></td>
                        </ng-container>
                    </table>

                    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>
