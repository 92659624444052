<div class="container-fluid">
    <div class="row mt-3 mb-1">
        <div class="col-12 seletor-flex">
            <soi-seletor-industria-distribuidora class="d-flex align-items-center flex-wrap"
                (industriaSelecionada)="setIndustriaSelecionada($event)"
                (distribuidoraSelecionada)="setDistribuidoraSelecionada($event)">
            </soi-seletor-industria-distribuidora>

            <soi-seletor-mes-ano (periodoSelecionado)="setPeriodoSelecionado($event)">
            </soi-seletor-mes-ano>

            <div class="acoes d-flex align-items-center">
                <soi-upload-arquivo
                    [parametros]="{ idIndustria: industriaSelecionada?.id, idDistribuidora: distribuidoraSelecionada?.id }">
                </soi-upload-arquivo>

                <button *ngIf="edicaoMovimentacaoHabilitada" mat-raised-button
                    matTooltip="{{ 'Salvar dados para edição posterior' | translate }}" color="primary" soiThrottleClick
                    (throttleClick)="salvarMovimentacao()" [throttleTime]="3000">
                    {{ "Salvar" | translate }}
                </button>

                <button *ngIf="edicaoMovimentacaoHabilitada" mat-raised-button
                    matTooltip="{{ 'Enviar e finalizar movimentação' | translate }}" color="primary" soiThrottleClick
                    (throttleClick)="enviarMovimentacao()" [throttleTime]="3000">
                    {{ "Enviar" | translate }}
                </button>

                <button *ngIf="edicaoMovimentacaoHabilitada" mat-raised-button
                    matTooltip="{{ 'Exportar dados de movimentação' | translate }}" color="primary"
                    (click)="exportarMovimentacao()">
                    {{ "Exportar" | translate }}
                </button>
                <button mat-raised-button matTooltip="{{ 'Buscar' | translate }}"
                    (click)="buscarDadosTabelaMovimentacao()" color="primary">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <soi-upload-arquivo-feedback></soi-upload-arquivo-feedback>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <soi-mensagem-notificacao [mostrar]="mesNaoRegistrado" [fechavel]="false"
                mensagem="{{ 'Mês não registrado.' | translate }}">
            </soi-mensagem-notificacao>

            <soi-mensagem-notificacao [mostrar]="distribuidoraSemProdutos" [fechavel]="false"
                mensagem="{{ 'Não existem produtos associados para essa distribuidora. Acione a indústria.' | translate }}">
            </soi-mensagem-notificacao>

            <soi-mensagem-notificacao [mostrar]="movimentacaoAbertaMesAnterior"
                mensagem="{{ 'Ainda existem movimentações anteriores em aberto. Envie-as para poder iniciar uma mais recente.' | translate }}">
            </soi-mensagem-notificacao>

            <div [hidden]="!tabelaMovimentacaoRenderizada" class="soi-table-container-sm">
                <mat-form-field *ngIf="tabelaMovimentacaoRenderizada" class="data-table-filter w-100 my-2">
                    <mat-label>{{ "Filtrar" | translate }}</mat-label>
                    <input matInput (keyup)="filtrarTabela($event.target.value)"
                        placeholder="{{ 'Digite o código, descrição ou quantidade' | translate }}">
                </mat-form-field>

                <div class="soi-table mat-elevation-z8">
                    <div class="status-movimentacao" [class.status-movimentacao-aberto]="edicaoMovimentacaoHabilitada"
                        [class.status-movimentacao-fechado]="!edicaoMovimentacaoHabilitada">
                        {{ statusMovimentacao | translate }}
                    </div>

                    <table mat-table [dataSource]="dataSourceTabelaMovimentacao" matSort>

                        <tr mat-header-row *matHeaderRowDef="colunasTabelaMovimentacao"></tr>


                        <tr mat-row *matRowDef="let row; columns: colunasTabelaMovimentacao;"></tr>

                        <ng-container matColumnDef="CodigoProdutoIndustria">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "Código" | translate }} </th>
                            <td mat-cell *matCellDef="let produto" class="coluna-codigo">
                                {{ produto.codigoProdutoIndustria }}
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="tdTotal"> Total </td>
                        </ng-container>

                        <ng-container matColumnDef="Descricao">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "Descrição" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let produto" class="coluna-descricao">
                                <div [ngSwitch]="this.lingua">
                                    <div *ngSwitchCase="'en'">{{ produto.descricaoIngles }}</div>
                                    <div *ngSwitchCase="'es'">{{ produto.descricaoEspanhol }}</div>
                                    <div *ngSwitchCase="'fr'">{{ produto.descricaoFrances }}</div>
                                    <div *ngSwitchDefault>{{ produto.descricao }}</div>
                                </div>
                            </td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <ng-container matColumnDef="Quantidade">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "Quantidade" | translate }}
                            </th>
                            <td mat-cell *matCellDef="let produto" class="coluna-quantidade soi-table-cell-right">
                                <input [readonly]="!edicaoMovimentacaoHabilitada" type="number"
                                    soiInputApenasNumerosInteiros value="{{ produto.quantidade }}"
                                    [(ngModel)]="produto.quantidade" class="input-quantidade" (change)="getTotais()">
                            </td>
                            <td mat-footer-cell *matFooterCellDef class="coluna-quantidade soi-table-cell-right">
                                <input readonly type="number" class="input-quantidade inputTotais"
                                    value="{{totalQuantidade}}">
                            </td>
                        </ng-container>

                        <span *ngIf="env == 'Kin'">
                            <!-- colunas somente KIN -->
                            <ng-container matColumnDef="Estoque">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "Estoque" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let produto" class="coluna-quantidade soi-table-cell-right">
                                    <input [readonly]="!edicaoMovimentacaoHabilitada" type="number"
                                        soiInputApenasNumerosInteiros value="{{ produto.estoque }}"
                                        [(ngModel)]="produto.estoque" class="input-quantidade" (change)="getTotais()">
                                </td>
                                <td mat-footer-cell *matFooterCellDef class="coluna-quantidade soi-table-cell-right">
                                    <input readonly type="number" class="input-quantidade inputTotais"
                                        value="{{totalEstoque}}">
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Valor">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "Valor" | translate }}
                                </th>
                                <td mat-cell *matCellDef="let produto" class="coluna-quantidade soi-table-cell-right">
                                    <input [readonly]="!edicaoMovimentacaoHabilitada" type="number" step="0.01"
                                        value="{{ produto.valor }}" [(ngModel)]="produto.valor" class="input-quantidade"
                                        (change)="getTotais()">
                                </td>
                                <td mat-footer-cell *matFooterCellDef class="coluna-quantidade soi-table-cell-right">
                                    <input readonly type="number" class="input-quantidade inputTotais"
                                        value="{{totalValor}}">
                                </td>
                            </ng-container>
                        </span>

                        <tr mat-footer-row *matFooterRowDef="colunasTabelaMovimentacao"></tr>

                    </table>

                    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
                </div>
            </div>
        </div>

    </div>
</div>