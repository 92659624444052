import { IndustriaApiService } from './../../../../comum/services/industria-api.service';
import { UsuarioApiService } from 'src/app/industria/services/usuario-api.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DistribuidoraApiService } from 'src/app/industria/services/distribuidora-api.service';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'soi-usuario-industria-form',
  templateUrl: './usuario-industria-form.component.html',
  styleUrls: ['./usuario-industria-form.component.css']
})
export class UsuarioIndustriaFormComponent implements OnInit {
  myControl = new FormControl('');
  options: Array<any>;
  filteredOptions: Observable<string[]>;
  form: FormGroup;
  industrias: Array<any>;
  distribuidoras: Array<any>;
  perfil_usuario: Array<any>;
  mostrarSenha: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<any>,
    private industriaApiService: IndustriaApiService,
    private distribuidoraApiService: DistribuidoraApiService,
    private usuarioApiService: UsuarioApiService
  ) { }
  ngOnInit(): void {
    this.carregarIndustrias();
    this.iniciarForm();
    this.getAllDistribuidora();
    this.getPerfilUsuario();
  }

  carregarIndustrias() {
    this.industriaApiService.getIndustrias()
      .subscribe(
        resposta => {
          this.industrias = resposta.dados?.sort((a, b) => a.nomeCompleto > b.nomeCompleto ? 1 : -1);
        },
        erro => {
        });
  }
  getAllDistribuidora() {
    this.distribuidoraApiService.getAll()
      .subscribe(
        resposta => {
          this.distribuidoras = resposta;
          this.ordenarPorNome();
          this.options = this.distribuidoras;
        },
        erro => {
        }
      )
  }
  getPerfilUsuario(){
    this.usuarioApiService.getPerfis()
    .subscribe(
      resposta => {
        this.perfil_usuario = resposta.dados;
      },
      erro => {
      }
    )
  }
  iniciarForm() {
    this.form = this.formBuilder.group({
      usuario: [null, [Validators.required]],
      senha: [null, [Validators.required]],
      email: [null, [Validators.required]],
      distribuidoras: [null, Validators.required],
      perfil_usuario: [null, Validators.required]
    });
  }
  cancelar() {
    this.dialogRef.close(false);
  }
  enviar() {
    this.inserir();
  }
  inserir() {
    this.usuarioApiService.inserirUsuarioIndustria(this.form.value)
      .subscribe(
        resposta => {
          if (resposta.sucesso) {
            this.snackBar.open('Usuário cadastrado');
            this.dialogRef.close(resposta.dados);
          }
          else if (resposta.errors === 'Usuário duplicado') {
            this.form.controls['usuario'].setErrors({ 'duplicado': true });
          }
          else {
            this.snackBar.open('Erro ao cadastrar usuário');
          }
        },
        erro => {
        }
      );
  }
  ordenarPorNome() {
    this.distribuidoras?.sort((a, b) => a.nomeCompleto.toLowerCase() > b.nomeCompleto.toLowerCase() ? 1 : -1);
  }
}
