<mat-toolbar *ngIf="mostrarMenu" class="mat-elevation-z1" color="{{ corMenu }}">
  <button mat-button *ngIf="usuarioLogado" [matMenuTriggerFor]="menu" class="hamburger-menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item [matMenuTriggerFor]="cadastros" *ngIf="permitidoCadastros">
      {{ "Cadastros" | translate }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="consultas" *ngIf="permitidoConsultas">
      {{ "Consultas" | translate }}
    </button>
    <button mat-menu-item [matMenuTriggerFor]="configuracoes" *ngIf="permitidoConfiguracoes">
      {{ "Configurações" | translate }}
    </button>
    <button mat-menu-item routerLink="distributor/sales-report" *ngIf="permitidoPainelDistribuidor">
      {{ "Painel do Distribuidor" | translate }}
    </button>
    <button mat-menu-item routerLink="distributor/dashboard" *ngIf="permitidoPainelDistribuidor">
      {{ "Dashboard do Distribuidor" | translate }}
    </button>

    <a target="_blank" mat-menu-item href="https://kinprofesional.kin.es/en/" *ngIf="permitidoPainelDistribuidor">{{ "Comprar produtos Kin" | translate }}</a>

    <mat-menu #cadastros="matMenu">
      <button mat-menu-item routerLink="industry/products">
        {{ "Produtos" | translate }}
      </button>
      <button mat-menu-item routerLink="industry/distributor">
        {{ "Distribuidores" | translate }}
      </button>
      <button mat-menu-item routerLink="industry/users">
        {{ "Usuários da Indústria" | translate }}
      </button>
    </mat-menu>

    <mat-menu #consultas="matMenu">
      <button mat-menu-item routerLink="industry/transactions">
        {{ "Movimentações" | translate }}
      </button>
      <button mat-menu-item routerLink="industry/status">
        {{ "Status" | translate }}
      </button>
    </mat-menu>

    <mat-menu #configuracoes="matMenu">
      <button mat-menu-item routerLink="industry/configurations/product-mix-association">
        {{ "Associação de mix de produtos" | translate }}
      </button>
      <button mat-menu-item routerLink="industry/configurations/warning-emails">
        {{ "E-mails de aviso" | translate }}
      </button>
    </mat-menu>
  </mat-menu>

  <a class="app-name">
    <ng-template [ngIf]="cliente == 'Kin'" [ngIfElse]="iconePadrao">
      <img class="tgt-icon" src="../../../../assets/img/Kin.png" style="width: 94px;">
      <span class="tgt-titulo d-none d-md-block"> Sell-Out Indústria </span>
    </ng-template>

    <ng-template #iconePadrao>
      <img class="tgt-icon" src="../../../../assets/img/target_logo.png" style="width: 32px;">
      <span class="tgt-titulo "> <b>Target Sistemas </b> <span class="d-sm-none "> | Sell-Out Indústria </span> </span>
    </ng-template>
  </a>

  <div class="acoes">
     <soi-seletor-linguagem *ngIf="usuarioLogado && mostrarSeletorIdioma"></soi-seletor-linguagem> 
    <!-- <soi-seletor-linguagem ></soi-seletor-linguagem> -->

    <div>
      <button mat-button color="secondary" (click)="logout()" *ngIf="usuarioLogado">
        <mat-icon>exit_to_app</mat-icon>
        {{ "Sair" | translate }}
      </button>
    </div>
  </div>
</mat-toolbar>

<soi-breadcrumb></soi-breadcrumb>
