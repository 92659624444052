import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MovimentacaoApiService } from 'src/app/industria/services/movimentacao-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ExportToCsv } from 'export-to-csv';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConfirmacaoDialogComponent } from 'src/app/comum/components/confirmacao-dialog/confirmacao-dialog.component';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'soi-dados-movimentacoes',
  templateUrl: './dados-movimentacoes.component.html',
  styleUrls: ['./dados-movimentacoes.component.css']
})

export class DadosMovimentacoesComponent {

  // controla se ambiente é KIN 
  env: any;

  industriaSelecionada: any;
  distribuidoraSelecionada: any;
  periodoSelecionado: Date;
  todasDistribuidoras: number = 0;

  movimentacaoAtual: any;
  statusMovimentacao: string;

  lingua = localStorage.getItem('lingua');

  listaMovimentacoes: any;
  tabelaMovimentacaoRenderizada: boolean = false;
  colunasTabelaMovimentacao: string[];
  dataSourceTabelaMovimentacao: any;
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  permitirAlteracaoStatus: boolean = false;

  /** Colunas Totais (quantidade, estoque e valor) */
  totalQuantidade = 0;
  totalEstoque = 0;
  totalValor = 0;

  constructor(
    private movimentacaoApiService: MovimentacaoApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.env = environment.client;
  }


  setColunasTabelaMovimentacao() {
    if (this.env == 'Kin') {
      if (this.industriaSelecionada && this.industriaSelecionada.id == 2) { //KIN CA
        this.colunasTabelaMovimentacao = ['codigoProdutoAgrupado', 'codigoProdutoIndustria', 'descricao', 'quantidade', 'estoque', 'valor'];
      } else { //KIN ES
        this.colunasTabelaMovimentacao = ['codigoProdutoAgrupado', 'codigoProdutoIndustria', 'descricao', 'quantidade', 'estoque'];
      }
    } else {
      this.colunasTabelaMovimentacao = ['codigoProdutoAgrupado', 'codigoProdutoIndustria', 'descricao', 'quantidade'];
    }
  }

  setIndustriaSelecionada(industria) {
    this.industriaSelecionada = industria;
  }

  setDistribuidoraSelecionada(distribuidora) {
    this.distribuidoraSelecionada = distribuidora;
  }

  setPeriodoSelecionado(periodoSelecionado: Date) {
    this.periodoSelecionado = periodoSelecionado;
  }

  getMovimentacao() {

    if (!this.industriaSelecionada) {
      let msgSelecioneFiltros = this.translate.instant("Aplique os filtros acima para visualizar as movimentações.");
      this.snackBar.open(msgSelecioneFiltros, '', { duration: 2000 });
      return;
    }

    this.setColunasTabelaMovimentacao();

    let filtro = {
      periodo: this.periodoSelecionado,
      idIndustria: this.industriaSelecionada.id,
      idDistribuidora: this.distribuidoraSelecionada?.id | this.todasDistribuidoras
    };
    this.statusMovimentacao = "";
    this.movimentacaoApiService.getMovimentacao(filtro)
      .subscribe(resposta => {
        this.exibirMensagem(resposta);
        if (resposta.sucesso == false) {
          return;
        }
        this.movimentacaoAtual = resposta.dados;
        this.listaMovimentacoes = this.movimentacaoAtual?.detalhes;
        if (this.movimentacaoAtual?.idMovimentacao > 0) {
          this.statusMovimentacao = this.movimentacaoAtual.finalizado ? "Finalizado" : "Em progresso";
        } else {
          this.statusMovimentacao = "";
        }

        this.construirTabelaMovimentacao();

        this.getTotais();

        if (typeof this.listaMovimentacoes === 'undefined' || this.listaMovimentacoes == null || Object.keys(this.listaMovimentacoes).length == 0) {
          let msg = this.translate.instant('Sem dados para exibir.')
          this.snackBar.open(msg, '', { duration: 2000 });
          return;
        }
      },
        erro => { });
  }

  construirTabelaMovimentacao() {
    this.dataSourceTabelaMovimentacao = new MatTableDataSource(this.listaMovimentacoes);
    this.dataSourceTabelaMovimentacao.paginator = this.paginador;
    this.dataSourceTabelaMovimentacao.paginator.pageIndex = 0;
    this.dataSourceTabelaMovimentacao.sort = this.sort;
    this.tabelaMovimentacaoRenderizada = this.dataSourceTabelaMovimentacao.data.length > 0;
    this.permitirAlteracaoStatus = this.tabelaMovimentacaoRenderizada && this.distribuidoraSelecionada?.id > 0 ? true : false;
  }

  construirFiltro() {
    this.dataSourceTabelaMovimentacao.filterPredicate = (data, filter) => {
      let dataStr = data.codigoProdutoIndustria.toString().concat(data.quantidade.toString());

      switch (this.lingua) {
        case 'en':
          dataStr += data.descricaoIngles;
          break;
        case 'es':
          dataStr += data.descricaoEspanhol;
          break;
        case 'fr':
          dataStr += data.descricaoFrances;
          break;
        default:
          dataStr += data.descricao;
          break;
      }

      return dataStr.toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1;
    }
  }

  exportar() {

    let filtro = {
      periodo: this.periodoSelecionado,
      idDistribuidora: this.distribuidoraSelecionada?.id ?? 0
    };

    this.movimentacaoApiService.getExportacao(filtro)
      .subscribe(resposta => {

        this.exibirMensagem(resposta);

        if (resposta.sucesso == false) {
          return;
        }

        let dadosExportacao = resposta.dados;

        if (this.env == 'Kin') {
          if (this.industriaSelecionada.id != 2) {
            dadosExportacao.forEach(de => {
              delete de.valor;
            });
          }
        } else { //se nao for KIN, remove colunas do relatorio
          dadosExportacao.forEach(de => {
            delete de.valor;
            delete de.estoque;
          });
        }

        let nomeDistribuidora = !this.distribuidoraSelecionada || this.distribuidoraSelecionada == 0 ?
          this.translate.instant('Todas') :
          this.distribuidoraSelecionada.nome;

        let filename = this.translate.instant('Movimentações') +
          '_' + nomeDistribuidora + '_' +
          moment(filtro.periodo).format('YYYYMM');

        const options = {
          filename: filename,
          fieldSeparator: ';',
          quoteStrings: '',
          decimalSeparator: '.',
          showLabels: false,
          showTitle: false,
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(dadosExportacao);
      });
  }

  filtrarTabela(filtro: string) {
    this.dataSourceTabelaMovimentacao.filter = filtro.toLowerCase();
  }

  alterarStatusMovimentacao() {
    this.confirmarAlteracaoStatusMovimentacao()
      .subscribe(
        confirmado => {
          if (confirmado) {
            let body = {
              idMovimentacao: this.movimentacaoAtual.idMovimentacao,
              statusAntigo: this.statusMovimentacao,
              statusNovo: this.statusMovimentacao == "Finalizado" ? "Em progresso" : "Finalizado"
            }
            this.movimentacaoApiService.alterarStatusMovimentacao(this.movimentacaoAtual.idMovimentacao, body)
              .subscribe(
                resposta => {
                  this.exibirMensagem(resposta);
                  if (resposta.sucesso == false) {
                    return;
                  }

                  this.movimentacaoAtual.finalizado = !this.movimentacaoAtual.finalizado;
                  this.statusMovimentacao = this.movimentacaoAtual.finalizado ? "Finalizado" : "Em progresso";
                },
                erro => {
                  this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'), '', { duration: 2000 });
                }
              );
          }
        },
        erro => { });
  }

  confirmarAlteracaoStatusMovimentacao(): Observable<boolean> {
    let mensagemDialog: string = this.translate.instant('O status da movimentação atual será alterado. Deseja continuar?');
    let dialogOptions: MatDialogConfig = {
      data: {
        mensagem: mensagemDialog
      }
    }
    const dialogRef = this.dialog.open(ConfirmacaoDialogComponent, dialogOptions);

    return dialogRef.afterClosed();
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }

  getTotais() {
    if (this.movimentacaoAtual) {
      this.totalQuantidade = this.movimentacaoAtual.detalhes.map(m => m.quantidade).reduce((acc, value) => acc + value, 0);
      this.totalEstoque = this.movimentacaoAtual.detalhes.map(m => m.estoque).reduce((acc, value) => acc + value, 0);
      this.totalValor = this.movimentacaoAtual.detalhes.map(m => m.valor).reduce((acc, value) => acc + value, 0);
      this.totalValor = Number(this.totalValor.toFixed(2));
    }
  }
}
