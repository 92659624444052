<div class="seletor-flex">
    <mat-form-field appearance="outline">
        <mat-label>{{ "Indústria" | translate }}</mat-label>
        <mat-select [(ngModel)]="industria" (selectionChange)="emitirIndustria()">
            <mat-option *ngFor="let industria of industrias" [value]="industria">
                {{ industria.nome }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>{{ "Distribuidora" | translate }}</mat-label>
        <mat-select [(ngModel)]="distribuidora" (selectionChange)="emitirDistribuidora()">
            <mat-option *ngIf="mostrarOpcaoTodas" [value]="0"> {{ "Todas" | translate }} </mat-option>
            <mat-option *ngFor="let distribuidora of industria?.distribuidoras " [value]="distribuidora">
                <div> <b>{{ distribuidora.nome }} </b></div>
                <div *ngIf="distribuidora.idSistemaExterno > 0">
                    {{ distribuidora.idSistemaExterno }} - {{ distribuidora.pais }}
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>