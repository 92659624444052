import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/comum/services/base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProdutoApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  inserir(produto) {
    return this.post(produto, 'Produto');
  }

  atualizar(produto) {
    return this.update(produto, `Produto/${produto.idProduto}`);
  }

  getMixProdutosDistribuidora(idIndustria: number, idDistribuidora: number) {
    const filtro = {
      IdDistribuidora: idDistribuidora,
      IdIndustria: idIndustria,
    };

    return this.post(filtro, 'DistribuidoraProduto/GetMixProdutos');
  }

  salvarMixDeProdutos(produtos: any) {
    return this.update(produtos, 'DistribuidoraProduto/Associar');
  }

  getProdutosPorIndustria(idIndustria: number) {
    return this.get(`Produto?idIndustria=${idIndustria}`);
  }

  getLinguagensPorProdutoId(idProduto: number) {
    return this.get(`Produto/Linguagens/${idProduto}`);
  }
}
