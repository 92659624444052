import { Component, ViewChild } from '@angular/core';
import { ProdutoApiService } from 'src/app/industria/services/produto-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { RetornoPadronizado } from 'src/app/compartilhado/RetornoPadronizado';

@Component({
  selector: 'soi-associacao-mix-produtos',
  templateUrl: './associacao-mix-produtos.component.html',
  styleUrls: ['./associacao-mix-produtos.component.css']
})
export class AssociacaoMixProdutosComponent {
  industriaSelecionada: any;
  distribuidoraSelecionada: any;
  lingua = localStorage.getItem('lingua');

  colunasTabelaAssociacao: string[] = ['descricao', 'codigoProdutoIndustria', 'codigoProdutoAgrupado', 'associado', 'masterToggleParcial'];
  dataSourceTabelaAssociacao: MatTableDataSource<any>;
  tabelaAssociacaoRenderizada: boolean = false;
  @ViewChild(MatPaginator) paginador: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectionParcial = new SelectionModel<any>(true, []);
  selecionadosInicialmente: SelectionModel<any>;

  constructor(
    private produtoApiService: ProdutoApiService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  setIndustriaSelecionada(industria) {
    this.industriaSelecionada = industria;
  }

  setLingua(lingua) {
    this.lingua = lingua;
  }

  ordernarProdutos(dataSource: any) {
    switch(this.lingua) {
      case 'en':
        dataSource.sort((a, b) => a.descricaoIngles > b.descricaoIngles ? 1 : -1);
        break;
      case 'es':
        dataSource.sort((a, b) => a.descricaoEspanhol > b.descricaoEspanhol ? 1 : -1);
        break;
      case 'fr':
        dataSource.sort((a, b) => a.descricaoFrances > b.descricaoFrances ? 1 : -1);
        break;
      default:
        dataSource.sort((a, b) => a.descricao > b.descricao ? 1 : -1);
        break;
    }
  }

  buscarMixProdutos(distribuidoraSelecionada: any) {
    this.distribuidoraSelecionada = distribuidoraSelecionada;
    this.produtoApiService.getMixProdutosDistribuidora(this.industriaSelecionada.id, distribuidoraSelecionada.idDistribuidora)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }
          let produtos = resposta.dados;

          this.construirTabelaAssociacao(produtos);
        });
  }

  construirTabelaAssociacao(dataSource: any) {
    this.ordernarProdutos(dataSource);
    this.dataSourceTabelaAssociacao = new MatTableDataSource(dataSource);
    this.dataSourceTabelaAssociacao.paginator = this.paginador;
    this.dataSourceTabelaAssociacao.paginator.pageIndex = 0;
    this.dataSourceTabelaAssociacao.sort = this.sort;
    this.tabelaAssociacaoRenderizada = this.dataSourceTabelaAssociacao.data.length > 0;

    this.selecionarDadosIniciais();
    this.selecionarDadosFiltrados();
  }

  construirFiltro() {
    this.dataSourceTabelaAssociacao.filterPredicate = (data, filter) => {
      let dataStr = data.codigoProdutoIndustria.toString().concat(data.codigoProdutoAgrupado.toString());

      switch(this.lingua) {
        case 'en':
          dataStr += data.descricaoIngles;
          break;
        case 'es':
          dataStr += data.descricaoEspanhol;
          break;
        case 'fr':
          dataStr += data.descricaoFrances;
          break;
        default:
          dataStr += data.descricao;
          break;
      }

      return dataStr.toLowerCase().indexOf(filter.trim().toLowerCase()) !== -1;
    }
  }

  selecionarDadosIniciais() {
    this.selecionadosInicialmente = new SelectionModel(true, this.dataSourceTabelaAssociacao.data.filter(produto => produto.associado));
  }

  salvarMixDeProdutos() {
    let reqBody = {
      idDistribuidora: this.distribuidoraSelecionada.idDistribuidora,
      produtosAssociados: this.getProdutosEditados()
    }

    if (reqBody.produtosAssociados.length == 0) {
      this.snackBar.open(this.translate.instant('Nenhuma alteração detectada'), '', { duration: 2000 });

      return;
    }

    this.produtoApiService.salvarMixDeProdutos(reqBody)
      .subscribe(
        resposta => {
          this.exibirMensagem(resposta);
          if (resposta.sucesso == false) {
            return;
          }
          let produtosAssociados = resposta.dados;

          this.construirTabelaAssociacao(produtosAssociados);
        },
        erro => {
          this.snackBar.open(this.translate.instant('Algo deu errado. Tente novamente.'), '', { duration: 2000 });
        });
  }

  getProdutosEditados() {
    return this.dataSourceTabelaAssociacao.data.filter(produto => {
      if ((produto.associado && !this.selecionadosInicialmente.isSelected(produto)) || (!produto.associado && this.selecionadosInicialmente.isSelected(produto))) {
        return 1;
      }

      return 0;
    });
  }

  filtrarProdutosDoMix(filtro: string) {
    this.dataSourceTabelaAssociacao.filter = filtro.toLowerCase();

    this.selecionarDadosFiltrados();
  }

  selecionarDadosFiltrados() {
    this.selectionParcial.clear();
    this.dataSourceTabelaAssociacao.filteredData.forEach(produto => {
      if (produto.associado) {
        this.selectionParcial.select(produto);
      }
    });
  }

  isAllSelected() {
    const numSelecionados = this.selectionParcial.selected.length;
    const numLinhas = this.dataSourceTabelaAssociacao?.filteredData.length;

    return numSelecionados === numLinhas;
  }

  clearSelection() {
    this.selectionParcial.clear();
    this.dataSourceTabelaAssociacao.filteredData.forEach(produto => {
      produto.associado = false;
    });
  }

  masterToggle() {
    this.isAllSelected() ?
      this.clearSelection() :
      this.dataSourceTabelaAssociacao.filteredData.forEach(produto => {
        produto.associado = true;
        this.selectionParcial.select(produto);
      });
  }

  exibirMensagem(retorno: RetornoPadronizado) {
    if (retorno?.mensagem == null || retorno?.mensagem == "") {
      return;
    }

    let parametros = retorno.parametrosMensagem ?? [];
    let tipoMensagem = "mensagem_padronizada." + retorno.mensagem;
    let mensagemTraduzida = this.translate.instant(tipoMensagem, parametros);
    this.snackBar.open(mensagemTraduzida, '', { duration: 2000 });
  }
}
