import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CarregamentoService } from 'src/app/comum/services/carregamento.service';

@Injectable()
export class CarregandoInterceptor implements HttpInterceptor {

  constructor(private carregamento: CarregamentoService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.carregamento.mostrar();

    return next.handle(request)
      .pipe(
        finalize(
          () => this.carregamento.esconder()
        )
      );
  }
}
