// Imports do Angular
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Componentes do módulo
import { AppComponent } from './app.component';

// Módulos de feature da aplicação
import { ComumModule } from './comum/comum.module';
import { IndustriaModule } from './industria/industria.module';
import { DistribuidoraModule } from './distribuidora/distribuidora.module';

// Interceptador de autenticação
import { AuthInterceptor } from './compartilhado/interceptors/auth-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CarregandoInterceptor } from './compartilhado/interceptors/carregando.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ComumModule,
    DistribuidoraModule,
    HttpClientModule,
    IndustriaModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CarregandoInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
