<mat-card *ngIf="mensagens">
    <mat-card-header>
        <mat-card-title>
            {{ "Feedback da importação do arquivo" | translate }}
        </mat-card-title>

        <button mat-icon-button (click)="mensagens=undefined">
            <mat-icon>close</mat-icon>
        </button>
    </mat-card-header>

    <!-- TODO: estilizar mensagem de acordo com o tipo de feedback? -->
    <mat-list>
        <mat-list-item *ngFor="let mensagem of mensagens">
          {{  mensagem.tipoMensagem | translate: {'0': mensagem.valor1, '1': mensagem.valor2, '2': mensagem.valor3 }  }}
        </mat-list-item>
    </mat-list>
</mat-card>
