import { Injectable } from '@angular/core';
import { throwError, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  get estaLogado() {
    return localStorage.getItem('dadosUsuario') != undefined && localStorage.getItem('tokenWebApi') != undefined;
  }

  get perfilUsuario() {
    let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'));

    return dadosUsuario.perfil;
  }

  usuarioLogado = new Subject<boolean>();
  usuarioLogado$ = this.usuarioLogado.asObservable();

  constructor() { }

  usuarioAutenticado(sucesso: boolean) {
    this.usuarioLogado.next(sucesso);
  }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getItem(key: string): string {
    return localStorage.getItem(key);
  }

  limpaSessao() {
    localStorage.removeItem('dadosUsuario');
    localStorage.removeItem('tokenWebApi');
    localStorage.clear();
    this.usuarioAutenticado(false);
  }

  setIndustriasUsuario() {
    let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'));
    let relacoesIndustriaDistribuidora: Array<any> = dadosUsuario.relacoesIndustriaDistribuidora;
    let industrias = new Array<any>();
    if (relacoesIndustriaDistribuidora != null) {
      relacoesIndustriaDistribuidora.forEach(relacao => {
        if (!industrias.find(industria => industria.id == relacao.industria.id)) {
          industrias.push({
            id: relacao.industria.id,
            nome: relacao.industria.nome,
            distribuidoras: this.getDistribuidorasDaIndustria(relacao.industria, relacoesIndustriaDistribuidora)
          });
        }
      });
    }

    industrias.sort((a, b) => a.nome > b.nome ? 1 : -1)

    localStorage.setItem('industriasUsuario', JSON.stringify(industrias));
  }

  getDistribuidorasDaIndustria(industria: any, relacoesIndustriaDistribuidora: Array<any>): Array<any> {

    let distribuidorasIndustria = relacoesIndustriaDistribuidora.filter(relacao => {
      return industria.id == relacao.industria.id;
    });

    return distribuidorasIndustria.map(relacao => {
      if (industria.id == relacao.industria.id) {
        return {
          idIndustriaDistribuidora: relacao.idIndustriaDistribuidora,
          id: relacao.distribuidora.id,
          nome: relacao.distribuidora.nome,
          idSistemaExterno: relacao.distribuidora.idSistemaExterno,
          pais: relacao.distribuidora.pais
        }
      }
    }).sort((a, b) => a.nome > b.nome ? 1 : -1);

  }
}
