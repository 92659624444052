import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
// import { MensagemSemTraducaoService } from './../services/ediwebapi/mensagem-sem-traducao.service';
import { Injector, Inject, Injectable } from '@angular/core';

@Injectable()
/**
 * Handler para traduções não encontradas, útil para descobrir eventuais frases esquecidas.
 */
export class TraducaoNaoExistenteHandler implements MissingTranslationHandler {
  timeoutEnvioSemTrad;

  constructor(@Inject(Injector) private injector: Injector) {
  }
  /**
   * Injeta o service que salva mensagens nesta classe
   */
//   private get mensagemSemTraducaoService(): MensagemSemTraducaoService {
//     return this.injector.get(MensagemSemTraducaoService);
//   }

  /**
   * Extende o MissingTranslationHandler.handle, lidando com traduções não encontradas
   */
  handle(params: MissingTranslationHandlerParams): any {
    if (localStorage.getItem('lingua') == 'pt') {
      return;
    }
    // armazenando em localstorage
    const separador = '!|!';
    let semTraducao = localStorage.getItem('sem_traducao');
    let arrSemTrad = [];
    if (semTraducao) {
      arrSemTrad = semTraducao.split(separador);
    }
    arrSemTrad.push(params.key);
    arrSemTrad = arrSemTrad.filter(function(value, index) { return arrSemTrad.indexOf(value) === index; });
    if (arrSemTrad.length) {
      this.enviaComDebounce(arrSemTrad);
    }
    semTraducao = arrSemTrad.join(separador);
    localStorage.setItem('sem_traducao', semTraducao);

    return params.key;
  }

  /**
   * Solicita envio de frases, utilizando um debounce de 30 segundos
   *
   * @param arrSemTrad array com frases sem tradução encontrada
   */
  enviaComDebounce(arrSemTrad: any[]): void {
    clearTimeout(this.timeoutEnvioSemTrad);
    this.timeoutEnvioSemTrad = setTimeout(() => {
      this.enviarSemTrad(arrSemTrad);
    }, 30000);
  }

  /**
   * Envia para a Api as frases sem tradução encontrada
   *
   * @param arrSemTrad array com frases sem tradução encontrada
   */
  enviarSemTrad(arrSemTrad: any[]): void {
    const dados = [];

    for (let i = 0; i < arrSemTrad.length; i++) {
      dados.push({
        'SIGLA_PAIS' : localStorage.getItem('lingua'),
        'MENSAGEM' : arrSemTrad[i]
      });
    }
    // this.mensagemSemTraducaoService.salvarMensagemSemTraducao(dados)
    //   .subscribe((data) => {
    //     console.log('Mensagens não traduzidas salvas com sucesso.');
    //   }, error => {
    //     console.log('Falha ao salvar mensagens não traduzidas.');
    //   });
  }
}
