<div mat-dialog-title>
  <div>
      {{ "Produto" | translate }}
  </div>
</div>

<div mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="enviar()">
      <mat-form-field>
          <mat-label>{{"Indústria" | translate}}</mat-label>
          <input matInput
              value="{{industriaSelecionada.nome.toUpperCase()}}"
              readonly required />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{"Descrição" | translate}}</mat-label>
        <input matInput formControlName="descricao" required />

        <mat-error *ngIf="form.controls['descricao'].hasError('required')">
            {{ "Campo obrigatório" | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
          <mat-label>{{"Código" | translate}}</mat-label>
          <input matInput formControlName="codigoProdutoIndustria" />
      </mat-form-field>

      <mat-form-field>
          <mat-label>{{"Código do Grupo" | translate}}</mat-label>
          <input matInput formControlName="codigoProdutoAgrupado" />
      </mat-form-field>

      <mat-expansion-panel  class="mb-3">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "Linguagens" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-form-field>
          <mat-label>{{"Descrição em inglês" | translate}}</mat-label>
          <input matInput formControlName="descricaoIngles" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"Descrição em espanhol" | translate}}</mat-label>
          <input matInput formControlName="descricaoEspanhol" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{"Descrição em francês" | translate}}</mat-label>
          <input matInput formControlName="descricaoFrances" />
        </mat-form-field>
      </mat-expansion-panel>
  </form>
</div>

<div mat-dialog-actions class="justify-content-end">
  <button mat-raised-button (click)="cancelar()">
      <mat-icon>close</mat-icon>
  </button>
  <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid" (click)="enviar()">
      <mat-icon>done</mat-icon>
  </button>
</div>
