import { PaisApiService } from 'src/app/industria/services/pais-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AutocompleteExigeSelecao } from 'src/app/compartilhado/validadores-form/autocomplete-exige-selecao';

@Component({
  selector: 'soi-seletor-pais',
  templateUrl: './seletor-pais.component.html',
  styleUrls: ['./seletor-pais.component.css']
})
export class SeletorPaisComponent implements OnInit {
  paises: Array<any>;

  paisesFiltrados: Observable<any>;;

  form: FormGroup;

  pais: FormControl;

  @Input() paisInicial;

  @Output() paisSelecionado = new EventEmitter<any>();

  @Output() componentePronto: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  constructor(
    private paisService: PaisApiService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
  ) {
    this.translate.onLangChange
      .subscribe(
        (event: LangChangeEvent) => { this.ordenarPorNome(this.paises) });
  }

  ngOnInit(): void {
    this.iniciarForm();
    this.obterPaises();
  }

  obterPaises() {
    this.paisService.getAll()
      .subscribe(
        resposta => {
          this.paises = this.ordenarPorNome(resposta.dados);

          this.paisesFiltrados = this.form.controls['pais'].valueChanges.pipe(
            startWith(this.paisInicial?.nome ?? ''),
            map(valor => this.filtrar(valor))
          );

          this.componentePronto.emit(this.form)
        },
        erro => {

        });
  }

  iniciarForm() {
    this.form = this.formBuilder.group({
      pais: [this.paisInicial, [Validators.required, AutocompleteExigeSelecao]]
    })
  }

  ordenarPorNome(paises: Array<any>) {
    return paises.sort((a, b) => this.translate.instant(a.nome).toLowerCase() > this.translate.instant(b.nome).toLowerCase() ? 1 : -1);
  }

  mostrarPorNome = (pais): string => {
    return pais && pais.nome ? this.translate.instant(pais.nome) : '';
  }

  filtrar(valorDigitado: any) {
    if (typeof valorDigitado === 'object' && valorDigitado.nome) {
      valorDigitado = this.translate.instant(valorDigitado.nome);
    }

    const valorFiltro = valorDigitado.toLowerCase();

    return this.paises.filter(pais => this.translate.instant(pais.nome).toLowerCase().indexOf(valorFiltro) === 0);
  }

  selecionarPais(pais: any) {
    this.paisSelecionado.emit(pais);
  }
}
