import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MovimentacaoApiService extends BaseApiService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  getMovimentacao(filtro: any) {
    return this.post(filtro, 'Movimentacao/GetMovimentacao/');
  }

  getDadosMovimentacao(filtro: any) {
    return this.getFullReponse(`Movimentacao/GetDadosMovimentacao?idDistribuidora=${filtro.idDistribuidora}&idIndustria=${filtro.idIndustria}&periodo=${filtro.periodo}`);
  }

  getExportacao(filtro: any) {
    return this.post(filtro, 'Movimentacao/Exportacao/');
  }

  salvarMovimentacao(movimentacao: any) {
    return this.post(movimentacao, "Movimentacao/Salvar");
  }
}
