import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'soi-seletor-linguagem',
  templateUrl: './seletor-linguagem.component.html',
  styleUrls: ['./seletor-linguagem.component.css']
})
export class SeletorLinguagemComponent implements OnInit {

  /**
   * Língua passada para o componente, deve ser selecionada
   */
  @Input() lingua: string;

  /**
   * Lista de códigos de línguas aceitáveis
   */
  linguasValidas = [
    'en',
    'es',
    'fr',
    'pt'
  ];

  /**
   * Array para construção do seletor de línguas
   */
  linguas = [
    {'valor': 'en', 'nome': 'English'},
    {'valor': 'es', 'nome': 'Español'},
    {'valor': 'fr', 'nome': 'Français'},
    {'valor': 'pt', 'nome': 'Português'}
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.linguasValidas.indexOf(this.lingua) === -1) {
      this.lingua = this.activatedRoute.snapshot.paramMap.get('lingua');
    }
    if (this.linguasValidas.indexOf(this.lingua) === -1) {
      this.lingua = localStorage.getItem('lingua');
    }
    if (this.linguasValidas.indexOf(this.lingua) === -1) {
      this.lingua = this.linguasValidas[0];
    }
    this.mudouLingua();
  }

  /**
   * Função disparada ao selecionar uma língua, seta ela na sessão e no módulo de tradução.
   */
  mudouLingua() {
    localStorage.setItem('lingua', this.lingua);
    this.translate.use(this.lingua);
    moment.locale(this.lingua);
  }
}
